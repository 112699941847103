import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from 'helpers/api'

const initialState = {
  feedbacks: [],
  isLoading: null,
  error: {}
}

export const getFeedbacks = createAsyncThunk(
  'feedbacks/getFeedbacks',
  async project_id => {
    const res = await api.Feedbacks.get({ params: { id: project_id } })
    return res.data
  }
)

export const feedbacksSlice = createSlice({
  name: 'feedbacks',
  initialState,
  reducers: {
    fetchFeedbacksSuccess(state, action) {
      state.feedbacks = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getFeedbacks.fulfilled, (state, action) => {
      state.feedbacks = action.payload
      state.isLoading = false
    })
    builder.addCase(getFeedbacks.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getFeedbacks.rejected, state => {
      state.isLoading = false
      state.error = 'error'
    })
  }
})

export const { fetchFeedbacksSuccess } = feedbacksSlice.actions
export default feedbacksSlice.reducer
