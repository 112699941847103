import React from 'react'

const Button = ( { text, onClick, className, classNameText } ) =>
{
  return (
    <button
      type="button"
      name="button"
      onClick={ onClick }
      className={ className }
    >
      <div className={ classNameText }>
        { text }
      </div>
    </button>
  )
}

export default Button
