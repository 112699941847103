import React from 'react'

const InputField = ({
  label,
  onChange,
  className,
  type,
  value,
  helperText,
  placeholder,
  multiline,
  color,
  helperColor,
}) => {

  function autoResizeTextarea() {
    const textarea = document.querySelector('.multiline');
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  return (
    <>
      {!multiline ? (
        <div className="input-container">
          <input
            type={type}
            onChange={onChange}
            className={color === 'black' ? 'input-field' : 'input-field white'}
            value={value}
            placeholder={placeholder}
          />
          {helperText && (
            <span className={helperColor === 'yellow' ? 'helper-text' : 'helper-text red-color'}>{helperText}</span>
          )}
        </div>
      ) : (
        <div className="input-container">
          <textarea
            type={type}
            onChange={onChange}
            onInput={autoResizeTextarea}
            className={color === 'black' ? 'input-field multiline' : 'input-field multiline white'}
            value={value}
            placeholder={placeholder}
          />
          {helperText && (
            <span className={helperColor === 'yellow' ? 'helper-text' : 'helper-text red-color'}>{helperText}</span>
          )}
        </div>
      )}
    </>
  )
}

export default InputField
