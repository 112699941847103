import React, { useEffect, useState } from 'react'

import { ReactComponent as Arrow } from 'images/Cases/arrow2.svg'
import api from 'helpers/api'

export const StaffAugmentTalents = () => {
  const [devs, setDevs] = useState([])

  useEffect(() => {
    api.Teammates.get({ params: { limit: 4 } })
      .then(({ data }) => setDevs(data))
      .catch(e => console.error(e))
  }, [])

  return (
    <section className="staff-augmentation__section">
      <div className="staff-augmentation__talent-header">
        <div className="staff-augmentation__title">
          <p className="staff-augmentation__title-text">Talents available</p>
        </div>

        <button className="staff-augmentation__devs-button">
          view all
          <div className="staff-augmentation__devs-button-icon">
            <Arrow />
          </div>
        </button>
      </div>

      <div className="staff-augmentation__talent-list">
        {devs.map(d => (
          <div className="staff-augmentation__talent" key={d.id + d.first_name}>
            <div
              className="staff-augmentation__talent-avatar"
              style={{
                background: d.avatar_url
                  ? `url(${d.avatar_url})`
                  : `linear-gradient(0deg, rgba(255,213,0,1) 50%, rgba(0,91,187,1) 50%)`
              }}
            >
              <span className="staff-augmentation__talent-availability">{d.availability}</span>
            </div>
            <p className="staff-augmentation__talent-name">{d.first_name}</p>
            <p className="staff-augmentation__talent-position">{d.position}</p>
            <div className="staff-augmentation__talent-skills">
              {d.skills.slice(0, 4).map(s => (
                <div key={d.first_name + s} className="staff-augmentation__talent-skill">
                  {s}
                </div>
              ))}
            </div>
            <div className="link-arrow-wrap">
              <Arrow className="link-arrow" />
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
