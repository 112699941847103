import React from "react"
import { useState } from 'react'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'

import One from '../../../images/Home/Numbers/1__start.svg'
import Two from '../../../images/Home/Numbers/2__start.svg'
import Three from '../../../images/Home/Numbers/3__start.svg'
import Four from '../../../images/Home/Numbers/4__start.svg'
import Five from '../../../images/Home/Numbers/5__start.svg'
import Six from '../../../images/Home/Numbers/6__start.svg'
import oneVideo from '../../../images/Home/Numbers/1__full.svg'
import twoVideo from '../../../images/Home/Numbers/2__full.svg'
import threeVideo from '../../../images/Home/Numbers/3__full.svg'
import fourVideo from '../../../images/Home/Numbers/4__full.svg'
import fiveVideo from '../../../images/Home/Numbers/5__full.svg'
import sixVideo from '../../../images/Home/Numbers/6__full.svg'
import Arrow from '../../../images/Home/Arrow.svg'

import Dropdown from "./Dorpdown/Dorpdown"

const data1 = [
  { value: 'Product discovery', label: 'Product discovery' },
  { value: 'Product audit', label: 'Product audit' },
  { value: 'Project management', label: 'Project management' },
  { value: 'Technical feasibility study', label: 'Technical feasibility study' },
  { value: 'Solutions mapping', label: 'Solutions mapping' },
  { value: 'Technologies selection', label: 'Technologies selection' },
]

const data2 = [
  { value: 'Website & data architecture', label: 'Website & data architecture' },
  { value: 'Complex app architecture', label: 'Complex app architecture' },
  { value: 'Custom website applications', label: 'Custom website applications' },
  { value: 'Hybrid & Cross-platform apps', label: 'Hybrid & Cross-platform apps' },
  { value: 'API Development & Integration', label: 'API Development & Integration' },
  { value: 'SaaS Development', label: 'SaaS Development' },
]

const data3 = [
  { value: 'Logo & Branding', label: 'Logo & Branding' },
  { value: 'Corporate Identity', label: 'Corporate Identity' },
  { value: 'Wireframing', label: 'Wireframing' },
  { value: 'UI/UX Design', label: 'UI/UX Design' },
  { value: 'HTML/CSS Prototype', label: 'HTML/CSS Prototype' },
  { value: 'Interactive Animations', label: 'Interactive Animations' },
]

const data4 = [
  { value: 'Product Audit', label: 'Product Audit' },
  { value: 'Unit Tests', label: 'Unit Tests' },
  { value: 'Functional Testing', label: 'Functional Testing' },
  { value: 'Non-Functional Testing', label: 'Non-Functional Testing' },
  { value: 'Manual Tests', label: 'Manual Tests' },
  { value: 'Tests Automation', label: 'Tests Automation' },
]

const data5 = [
  { value: 'CI/CD', label: 'CI/CD' },
  { value: 'Cloud Infrastructure Administration', label: 'Cloud Infrastructure Administration' },
  { value: 'Database Administration & Support', label: 'Database Administration & Support' },
  { value: 'Site Reliability Engineering', label: 'Site Reliability Engineering' },
  { value: 'Supply Chain Management', label: 'Supply Chain Management' },
  { value: 'IaaS/PaaS', label: 'IaaS/PaaS' },
]

const data6 = [
  { value: 'L2-L3 Support', label: 'L2-L3 Support' },
  { value: 'Code Refactoring', label: 'Code Refactoring' },
  { value: 'Bug Fixing & Troubleshooting', label: 'Bug Fixing & Troubleshooting' },
  { value: 'Performance Optimization', label: 'Performance Optimization' },
  { value: 'Cloud & Data Migration', label: 'Cloud & Data Migration' },
  { value: 'Software Re-engineering', label: 'Software Re-engineering' },
]

const Competences = ( props ) =>
{
  const [ items1 ] = useState( data1 )
  const [ items2 ] = useState( data2 )
  const [ items3 ] = useState( data3 )
  const [ items4 ] = useState( data4 )
  const [ items5 ] = useState( data5 )
  const [ items6 ] = useState( data6 )

  const mouseIn1 = () =>
  {
    var video = document.getElementById( "video1" )
    video.setAttribute( 'src', '' )
    video.setAttribute( 'src', oneVideo )
  }

  const mouseIn2 = () =>
  {
    var video = document.getElementById( "video2" )
    video.setAttribute( 'src', '' )
    video.setAttribute( 'src', twoVideo )
  }

  const mouseIn3 = () =>
  {
    var video = document.getElementById( "video3" )
    video.setAttribute( 'src', '' )
    video.setAttribute( 'src', threeVideo )
  }

  const mouseIn4 = () =>
  {
    var video = document.getElementById( "video4" )
    video.setAttribute( 'src', '' )
    video.setAttribute( 'src', fourVideo )
  }

  const mouseIn5 = () =>
  {
    var video = document.getElementById( "video5" )
    video.setAttribute( 'src', '' )
    video.setAttribute( 'src', fiveVideo )
  }

  const mouseIn6 = () =>
  {
    var video = document.getElementById( "video6" )
    video.setAttribute( 'src', '' )
    video.setAttribute( 'src', sixVideo )
  }

  return (
    <div className="about__cards">
      <div className="about__card-services" onMouseEnter={ mouseIn1 }>
        <div className="about__card-services-column">
          <div className="about__card-row-image">
            <img src={ One } alt="about" className="about__card-services-image" />
            <img id="video1" src={ oneVideo } alt="arrow" className="about__card-services-image-full" />
            <div className="about__arrow">
              <ArrowOutwardIcon />
            </div>
          </div>
          <div className="about__card-row-text">
            <div className="about__card-services-text">
              Consulting Services
            </div>
          </div>
          <div className="about__card-services-column-second">
            { items1.map( ( item ) => (
              <div key={ item.label } className="about__card-services-row">
                <img src={ Arrow } alt="arrow" className="about__card-services-row-text-img" />
                <div className="about__card-services-row-text">
                  { item.label }
                </div>
              </div>
            ) ) }
          </div>
        </div>

        <div className="about__tablet-row">
          <div className="about__card-tablet-text">
            Consulting
          </div>
          <div className="about__arrow">
            <ArrowOutwardIcon />
          </div>
        </div>
        <div className="about__tablet-column">
          { items1.map( ( item ) => (
            <div key={ item.label } className="about__card-services-row">
              <img src={ Arrow } alt="arrow" className="about__card-services-row-text-img" />
              <div className="about__card-tablet-row-text">
                { item.label }
              </div>
            </div>
          ) ) }
        </div>
      </div>

      <div className="about__card-services" onMouseEnter={ mouseIn2 }>
        <div className="about__card-services-column">
          <div className="about__card-row-image">
            <img src={ Two } alt="about" className="about__card-services-image" />
            <img id="video2" src={ twoVideo } alt="arrow" className="about__card-services-image-full" />
            <div className="about__arrow">
              <ArrowOutwardIcon />
            </div>
          </div>
          <div className="about__card-row-text">
            <div className="about__card-services-text">
              Software development
            </div>
          </div>
          <div className="about__card-services-column-second">
            { items2.map( ( item ) => (
              <div key={ item.label } className="about__card-services-row">
                <img src={ Arrow } alt="arrow" className="about__card-services-row-text-img" />
                <div className="about__card-services-row-text">
                  { item.label }
                </div>
              </div>
            ) ) }
          </div>
        </div>

        <div className="about__tablet-row">
          <div className="about__card-tablet-text">
            Software
          </div>
          <div className="about__arrow">
            <ArrowOutwardIcon />
          </div>
        </div>
        <div className="about__tablet-column">
          { items2.map( ( item ) => (
            <div key={ item.label } className="about__card-services-row">
              <img src={ Arrow } alt="arrow" className="about__card-services-row-text-img" />
              <div className="about__card-tablet-row-text">
                { item.label }
              </div>
            </div>
          ) ) }
        </div>
      </div>

      <div className="about__card-services" onMouseEnter={ mouseIn3 }>
        <div className="about__card-services-column">
          <div className="about__card-row-image">
            <img src={ Three } alt="about" className="about__card-services-image" />
            <img id="video3" src={ threeVideo } alt="about" className="about__card-services-image-full" />
            <div className="about__arrow">
              <ArrowOutwardIcon />
            </div>
          </div>
          <div className="about__card-row-text">
            <div className="about__card-services-text">
              Design
            </div>
          </div>
          <div className="about__card-services-column-second">
            { items3.map( ( item ) => (
              <div key={ item.label } className="about__card-services-row">
                <img src={ Arrow } alt="arrow" className="about__card-services-row-text-img" />
                <div className="about__card-services-row-text">
                  { item.label }
                </div>
              </div>
            ) ) }
          </div>
        </div>

        <div className="about__tablet-row">
          <div className="about__card-tablet-text">
            Design
          </div>
          <div className="about__arrow">
            <ArrowOutwardIcon />
          </div>
        </div>
        <div className="about__tablet-column">
          { items3.map( ( item ) => (
            <div key={ item.label } className="about__card-services-row">
              <img src={ Arrow } alt="arrow" className="about__card-services-row-text-img" />
              <div className="about__card-tablet-row-text">
                { item.label }
              </div>
            </div>
          ) ) }
        </div>
      </div>

      <div className="about__card-services" onMouseEnter={ mouseIn4 }>
        <div className="about__card-services-column">
          <div className="about__card-row-image">
            <img src={ Four } alt="about" className="about__card-services-image" />
            <img id="video4" src={ fourVideo } alt="about" className="about__card-services-image-full" />
            <div className="about__arrow">
              <ArrowOutwardIcon />
            </div>
          </div>
          <div className="about__card-row-text">
            <div className="about__card-services-text">
              Quality assurance
            </div>
          </div>
          <div className="about__card-services-column-second">
            { items4.map( ( item ) => (
              <div key={ item.label } className="about__card-services-row">
                <img src={ Arrow } alt="arrow" className="about__card-services-row-text-img" />
                <div className="about__card-services-row-text">
                  { item.label }
                </div>
              </div>
            ) ) }
          </div>
        </div>

        <div className="about__tablet-row">
          <div className="about__card-tablet-text">
            Quality assurance
          </div>
          <div className="about__arrow">
            <ArrowOutwardIcon />
          </div>
        </div>
        <div className="about__tablet-column">
          { items4.map( ( item ) => (
            <div key={ item.label } className="about__card-services-row">
              <img src={ Arrow } alt="arrow" className="about__card-services-row-text-img" />
              <div className="about__card-tablet-row-text">
                { item.label }
              </div>
            </div>
          ) ) }
        </div>
      </div>

      <div className="about__card-services" onMouseEnter={ mouseIn5 }>
        <div className="about__card-services-column">
          <div className="about__card-row-image">
            <img src={ Five } alt="about" className="about__card-services-image" />
            <img id="video5" src={ fiveVideo } alt="about" className="about__card-services-image-full" />
            <div className="about__arrow">
              <ArrowOutwardIcon />
            </div>
          </div>
          <div className="about__card-row-text">
            <div className="about__card-services-text">
              DevOps
            </div>
          </div>
          <div className="about__card-services-column-second">
            { items5.map( ( item ) => (
              <div key={ item.label } className="about__card-services-row">
                <img src={ Arrow } alt="arrow" className="about__card-services-row-text-img" />
                <div className="about__card-services-row-text">
                  { item.label }
                </div>
              </div>
            ) ) }
          </div>
        </div>

        <div className="about__tablet-row">
          <div className="about__card-tablet-text">
            DevOps
          </div>
          <div className="about__arrow">
            <ArrowOutwardIcon />
          </div>
        </div>
        <div className="about__tablet-column">
          { items5.map( ( item ) => (
            <div key={ item.label } className="about__card-services-row">
              <img src={ Arrow } alt="arrow" className="about__card-services-row-text-img" />
              <div className="about__card-tablet-row-text">
                { item.label }
              </div>
            </div>
          ) ) }
        </div>
      </div>

      <div className="about__card-services" onMouseEnter={ mouseIn6 }>
        <div className="about__card-services-column">
          <div className="about__card-row-image">
            <img src={ Six } alt="about" className="about__card-services-image" />
            <img id="video6" src={ sixVideo } alt="about" className="about__card-services-image-full" />
            <div className="about__arrow">
              <ArrowOutwardIcon />
            </div>
          </div>
          <div className="about__card-row-text">
            <div className="about__card-services-text">
              Support & Maintenance
            </div>
          </div>
          <div className="about__card-services-column-second">
            { items6.map( ( item ) => (
              <div key={ item.label } className="about__card-services-row">
                <img src={ Arrow } alt="arrow" className="about__card-services-row-text-img" />
                <div className="about__card-services-row-text">
                  { item.label }
                </div>
              </div>
            ) ) }
          </div>
        </div>

        <div className="about__tablet-row">
          <div className="about__card-tablet-text">
            Support
          </div>
          <div className="about__arrow">
            <ArrowOutwardIcon />
          </div>
        </div>
        <div className="about__tablet-column">
          { items6.map( ( item ) => (
            <div key={ item.label } className="about__card-services-row">
              <img src={ Arrow } alt="arrow" className="about__card-services-row-text-img" />
              <div className="about__card-tablet-row-text">
                { item.label }
              </div>
            </div>
          ) ) }
        </div>
      </div>

      <div className="about__test">
        <Dropdown title='Consulting' items={ items1 } />
        <Dropdown title='Software' items={ items2 } />
        <Dropdown title='Design' items={ items3 } />
        <Dropdown title='Quality assurance' items={ items4 } />
        <Dropdown title='DevOps' items={ items5 } />
        <Dropdown title='Support' items={ items6 } />
      </div>
    </div>
  )
}

export default Competences
