import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from 'helpers/api'

const initialState = {
  services: [],
  isLoading: null,
  error: {}
}

export const getServices = createAsyncThunk(
  'services/getServices',
  async project_id => {
    const res = await api.Services.get({
      params: { id: project_id }
    })
    return res.data
  }
)

export const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    fetchServicesSuccess(state, action) {
      state.services = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getServices.fulfilled, (state, action) => {
      state.services = action.payload
      state.isLoading = false
    })
    builder.addCase(getServices.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getServices.rejected, state => {
      state.isLoading = false
      state.error = 'error'
    })
  }
})

export const { fetchServicesSuccess } = servicesSlice.actions
export default servicesSlice.reducer
