import React from "react"

const NothingFound = ( props ) =>
{
  return (
    <div className="cases__column-not-found">
      <div className="cases__text-not-found">
        Nothing has been found
      </div>
      <div className="cases__subtext-not-found">
        We couldn't find what you searched for.
        Try searching again please.
      </div>
    </div>
  )
}

export default NothingFound
