import React, { useState } from "react"
import { Link } from 'react-router-dom'
import parse from "html-react-parser"

import Button from '../../reusable/Button/Button'
import LetsTalk from "../../LetsTalk/LetsTalk"
import Features from "../Features/Features"
import Arrow from "../../../images/Cases/arrow2.svg"

const Project = ( props ) =>
{
  const project = props.project.data
  const technologies = props.technologies
  const cases = props.cases
  const slicedCases = cases.slice( 0, 12 )
  const services = props.services
  const servicesLength = services.length
  const solutions = props.solutions
  const feedbacks = props.feedbacks
  const features = props.features
  const id = project.id

  const technologiesProject = technologies.filter( technology => technology.project_id === project.id )

  const [ feedbackOpened, setFeedbackOpened ] = useState(0)

  const onClickNext = () =>
  {
    if ( feedbackOpened === (feedbacks.length - 1) )
    {
      setFeedbackOpened( 0 )
    }
    else
    {
      setFeedbackOpened( feedbackOpened + 1 )
    }
  }

  const onClickLeft = () =>
  {
    document.getElementById( "container" ).scrollLeft += 200
  }

  const onClickRight = () =>
  {
    document.getElementById( "container" ).scrollLeft -= 200
  }

  const onClickPrivious = () =>
  {
    if ( feedbackOpened === 0 )
    {
      setFeedbackOpened( feedbacks.length - 1 )
    }
    else
    {
      setFeedbackOpened( feedbackOpened - 1 )
    }
  }

  const url = window.location.origin

  const slider = document.getElementById( "container" )
  let mouseDown = false
  let startX, scrollLeft

  let startDragging = function ( e )
  {
    mouseDown = true
    startX = e.pageX - slider.offsetLeft
    scrollLeft = slider.scrollLeft
  }
  let stopDragging = function ( event )
  {
    mouseDown = false
  }

  if(slider) {
    slider.addEventListener( 'mousemove', ( e ) =>
    {
      e.preventDefault()
      if ( !mouseDown ) { return; }
      const x = e.pageX - slider.offsetLeft
      const scroll = x - startX
      slider.scrollLeft = scrollLeft - scroll
    } )
    slider.addEventListener( 'mousedown', startDragging, false )
    slider.addEventListener( 'mouseup', stopDragging, false )
    slider.addEventListener( 'mouseleave', stopDragging, false )
  }

  return (
    <div className="project__wrapper">
      <div className="project__block-first">
        <div className="project__column-title">
          <div className="project__title">
            { project.title }
          </div>
          <div className="project__row-subtitle">
            <div className="project__subtitle">
              { project.years }&nbsp;
            </div>
            <hr className="project__line" />
            <div className="project__description">
              <div className="project__subtitle-2">
                &nbsp;{ project.description_long }
              </div>
            </div>
          </div>
        </div>

        <div className="project__images">
          <img src={ project.image_home_1_url } alt="progect" className="project__image-1" />
          <img src={ project.image_home_2_url } alt="progect" className="project__image-2" />
        </div>
      </div>

      <div className="project__block-second">
        <div className="project__highlights">
          Project Highlights
        </div>
        <div className="project__row-highlights">
          <div className="project__column-highlights">
            <div className="project__highlights-title">
              Client from:
            </div>
            <div className="project__highlights-content">
              { project.client_location }
            </div>
          </div>
          <div className="project__column-highlights">
            <div className="project__highlights-title">
              Industry:
            </div>
            <div className="project__highlights-content">
              { project.industry }
            </div>
          </div>
          <div className="project__column-highlights">
            <div className="project__highlights-title">
              Project structure:
            </div>
            <div className="project__highlights-content">
              { project.structure }
            </div>
          </div>
          <div className="project__column-highlights">
            <div className="project__highlights-title">
              Our role:
            </div>
            <div className="project__highlights-content">
              { services.map( ( item, index ) => (
                <>
                  { (index === servicesLength - 1) ?
                    <>{ item.name }</> :
                    <>{ item.name },&nbsp;</>
                  }
                </>
              ) ) }
            </div>
          </div>
          <div className="project__column-highlights-technologies">
            <div className="project__highlights-title">
              Technologies:
            </div>
            <div className="project__highlights-technologies">
              { technologiesProject.map((item) => (
                <Button text={ item.name } className='project__technologies-button' classNameText='project__highlights-content-2' />
              ))}
            </div>
          </div>
        </div>

        <hr className="project__hr-1" />

        <div className="project__row-client">
          <div className="project__client">
            <div className="project__about-title">
              About the client
            </div>
            <div className="project__about-subtitle">
              { parse( project.about_client )}
            </div>
          </div>
          <div className="project__client">
            <div className="project__about-title">
              Problem statement
            </div>
            <div className="project__about-subtitle">
              { parse( project.problem_statment ) }
            </div>
          </div>
        </div>
      </div>

      <div className="project__block-third" style={ { backgroundImage: `url("${ project.large_image_url }")`}}>
        <div className="project__block-third-grain" />
      </div>

      <div className="project__block-second">
        <div className="project__row-project">
          <div className="project__main-title">
            About the project
          </div>
          <div className="project__column-project">
            { solutions.map( ( item ) => (
              <>
                <div className="project__client-text">
                  <div className="project__about-title">
                    { window.innerWidth <= 780 ?
                      <>Solutions</>
                      :
                      <>Solutions development</>
                    }
                  </div>
                  <div className="project__about-subtitle">
                    { parse( item.content ) }
                  </div>
                </div>
                <hr className="project__hr" />
              </>
            ) ) }
          </div>
        </div>
      </div>

      <Features features={ features } image={ project.features_image_url } />

      <div className="project__block-second">
        <div className="project__buisness">
          Business outcomes
        </div>
        <div className="project__row-project-1">
          <div className="project__column-buisness">
            <div className="project__hr" />
            <div className="project__client-text">
              <div className="project__buisness-subtitle">
                { project.outcomes_first } 
              </div>
            </div>
          </div>
          <div className="project__column-buisness">
            <div className="project__hr" />
            <div className="project__client-text">
              <div className="project__buisness-subtitle">
                { project.outcomes_second } 
              </div>
            </div>
          </div>
          <div className="project__column-buisness">
            <div className="project__hr" />
            <div className="project__client-text">
              <div className="project__buisness-subtitle">
                { project.outcomes_third } 
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="project__block-fours" style={ { backgroundImage: `url("${ project.large_image_second_url }")` } }>
        { project.small_image_url &&
          <div className="project__image-on-image-block">
            <img src={ project.small_image_url } alt="progect" className="project__image-on-image" />
          </div>
        }
      </div>

      <div className="project__block-second">
        <div className="project__row-project">
          <div className="project__feedback-title">
            Feedback from the client
          </div>
          {feedbacks.length > 0 &&
            <>
              { feedbacks.map( ( feedback, index ) =>
              {
                const content = parse( feedback.content )
                return (
                  <>
                    { index === feedbackOpened &&
                      <div className="project__column-feedback">
                        <div className="project__client-text">
                          <div className="project__feedback-subtitle">
                            { content }
                          </div>
                        </div>
                        <div className="project__client-text">
                          <div className="project__feedback-user-row">
                            <div className="project__row-avatar">
                              <img src={ feedback.avatar_url } alt="project" className="project__avatar" />
                              <div className="project__avatar-column">
                                <div className="project__position">
                                  { feedback.position }
                                </div>
                                <div className="project__location">
                                  { feedback.location }
                                </div>
                              </div>
                            </div>
                            <div className="project__row-avatar">
                              <div className="project__read-more">
                                <Button
                                  text={ <><img src={ Arrow } alt='arrow' className='project__arrow-1' /></> }
                                  className='project__button'
                                  onClick={ onClickPrivious } />
                                <a href={ project.link } className="project__link" >
                                  { window.innerWidth <= 780 ?
                                    <>on Clutch</>
                                    :
                                    <>Read more on Clutch</>
                                  }
                                </a>
                                <Button
                                  text={ <><img src={ Arrow } alt='arrow' className='project__arrow-2' /></> }
                                  className='project__button'
                                  onClick={ onClickNext } />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </>
                )
              } ) }
            </> 
          }
        </div>
      </div>

      <div className="project__block-second">
        <div className="project__row-discover">
          <div className="project__discover-title">
            Discover other cases
          </div>
          <div className="project__all-cases">
            <Button
              text={ <><img src={ Arrow } alt='arrow' className='project__arrow-1' /></> }
              className='project__button'
              onClick={ onClickRight } />
            <Link to='/cases' className="project__link">
              All Cases
            </Link>
            <Button
              text={ <><img src={ Arrow } alt='arrow' className='project__arrow-2' /></> }
              className='project__button'
              onClick={ onClickLeft } />
          </div>
        </div>
        <hr className="project__discover-hr" />

        <div className="project__cards" id="container">
          { slicedCases.map((project) => {
            const projectTechnologies = technologies?.filter( technology => technology.project_id === project.id )
            const slicedTechnologies = projectTechnologies.slice( 0, 3 )
            return (
              <>
              { id !== project.id &&
                  <div className="project__card">
                    <div className="project__card-title-row">
                      <div className="project__card-title-column">
                        <a className="project__card-title" href={ url + '/portfolio/' + project.id }>
                          { project.title }
                        </a>
                        <div className="project__card-subtitle">
                          { project.description }
                        </div>
                      </div>
                      <div className="project__card-buttons-row">
                        { slicedTechnologies.map( ( technology ) => (
                          <Button text={ technology.name } className='project__technologies-button-2' classNameText='cases__technologies-button-text' />
                        ) ) }
                      </div>
                    </div>
                    <div className="cases__image-conteiner">
                      <img src={ project.image_main_url } alt="progect" className="project__image-main" />
                    </div>
                  </div>
              }
              </>
          )})}
        </div>
      </div>

      <div className="project__block-second">
        <div className="project__talk">
          <LetsTalk />
        </div>
      </div>
    </div>
  )
}

export default Project
