import React from 'react'

import { TextBlocksWithIcons } from 'components/reusable/TextBlocks/with-icons'
import {
  SADevelopIcon,
  SAManageIcon,
  SAPaperworkIcon,
  SAProductIcon,
  SAScaleIcon,
  SAStackIcon
} from 'images/StaffAugmentation'

const texts = [
  'Companies that want to manage the team on their side',
  'Products who wish to add extra resources to the core team to scale fast',
  'Companies that want to hire professionals dedicated to their development only',
  'Businesses that need exact specialists good at tech stack used previously on the product',
  'Startups that need a well-designed product without sacrificing quality or budget overrun',
  "Those who don't want to spend months on local hires and managing paperwork"
]

const icons = [
  <SAManageIcon />,
  <SAScaleIcon />,
  <SADevelopIcon />,
  <SAStackIcon />,
  <SAProductIcon />,
  <SAPaperworkIcon />
]

export const StaffAugmentTextBlocks = () => (
  <TextBlocksWithIcons title={['The augmentation model', 'the best works for']} texts={texts} icons={icons} />
)
