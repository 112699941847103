import LetsTalk from 'components/LetsTalk/LetsTalk'
import { StaffAugmentDevsAvailable } from 'components/StaffAugmentation/devs-available'
import React from 'react'

const Pricing = () => {

  return (
    <div className="pricing__container">
      <div className="pricing__top-bg"></div>
      <div className="pricing__choose_container">
        <StaffAugmentDevsAvailable />
      </div>
      <div className="pricing__bottom-bg">
        <LetsTalk/>
      </div>
    </div>
  )
}

export default Pricing
