import React from "react"
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { includes } from 'lodash'

import Button from '../reusable/Button/Button'
import Select from "./Select/Select"
import LetsTalk from "../LetsTalk/LetsTalk"
import NothingFound from "./nothingFound"
import Loader from "./Loader"
import Plus from '../../images/Cases/Plus.svg'

const data1 = [
  { value: 'Healthcare', label: 'Healthcare', checked: false },
  { value: 'FinTech', label: 'FinTech', checked: false },
  { value: 'eCommerce', label: 'eCommerce', checked: false },
  { value: 'Fitness & Wellness', label: 'Fitness & Wellness', checked: false },
  { value: 'eLearning', label: 'eLearning', checked: false },
  { value: 'Other,IT Services and IT Consulting,Real Estate,Military,Social Networking,MarTech,Media & Entertainment', label: 'Other Solutions', checked: false },
]

const data2 = [
  { value: 'Project Management', label: 'Project Management' },
  { value: 'Web Development', label: 'Web Development' },
  { value: 'UX/UI Design', label: 'UX/UI Design' },
  { value: 'QA', label: 'Quality assurance' },
  { value: 'DevOps', label: 'DevOps' },
  { value: 'Mobile Development', label: 'Mobile Development' },
]

const data3 = [
  { value: 'Front-end', label: 'Front-end' },
  { value: 'Back-end', label: 'Back-end' },
  { value: 'Databases', label: 'Databases' },
  { value: 'Mobile', label: 'Mobile' },
  { value: 'Infrastructure', label: 'Infrastructure' },
  { value: 'DevOps', label: 'DevOps' },
]

const Cases = (props) =>
{
  const projects = props.projects
  const technologies = props.technologies
  const industry = props.industry
  const services = props.services
  const techs = props.techs
  const isLoadingCases = props.isLoadingCases
  const isLoadingTechnologies = props.isLoadingTechnologies

  const [ items1 ] = useState( data1 )
  const [ items2 ] = useState( data2 )
  const [ items3 ] = useState( data3 )

  const onClick = ( element, array, setArray ) =>
  {
    if ( includes( array, element ) )
    {
      setArray( array.filter( item => item !== element ) )
    }
    else
    {
      setArray( arr => [ ...arr, element ] )
    }
  }

  return (
    <div className="cases__wrapper">
      <div className="cases__row-title">
        <div className="cases__title">
          Portfolio
        </div>
        <div className="cases__row-dropdowns">
          
          <div className="cases__row-expand-1">
            <Select title='Industries' items={ items1 } industry={ industry } setIndustry={ props.setIndustry } />
          </div>
          <div className="cases__row-expand-2">
            <Select title='Services' items={ items2 } industry={ props.services } setIndustry={ props.setServices } />
          </div>
          <div className="cases__row-expand-3">
            <Select title='Technologies' items={ items3 } industry={ props.techs } setIndustry={ props.setTechnologies } />
          </div>
        </div>
      </div>

      { ( ( industry.length > 0 ) || ( services.length > 0 ) || ( techs.length > 0 ) ) &&
        <div className="cases__row-options">
          <div className="cases__text-options">
            Selected options
          </div>
          <div className="cases__wrap-options">
            { industry.map( ( item ) => (
              <React.Fragment key={item}>
                { ( item === "Other,IT Services and IT Consulting,Real Estate,Military,Social Networking,MarTech,Media & Entertainment" ) ?
                  <Button
                    text={ <> Other &nbsp; <img src={ Plus } alt="plus" style={ { transform: 'rotate( 45deg )' } } /></> }
                    className='cases__options-button'
                    classNameText='cases__options-button-text'
                    onClick={ () => onClick( item, industry, props.setIndustry ) } />
                  :
                  <Button
                    text={ <>{ item } &nbsp; <img src={ Plus } alt="plus" style={ { transform: 'rotate( 45deg )' } } /></> }
                    className='cases__options-button'
                    classNameText='cases__options-button-text'
                    onClick={ () => onClick( item, industry, props.setIndustry ) } />
                }
              </React.Fragment>
            ) ) }

            { services.map( ( item ) => (
              <Button
                key={item}
                text={ <>{ item } &nbsp; <img src={ Plus } alt="plus" style={ { transform: 'rotate( 45deg )' } } /></> } 
                className='cases__options-button' 
                classNameText='cases__options-button-text' 
                onClick={ () => onClick( item, services, props.setServices ) } />
            ) ) }

            { techs.map( ( item ) => (
              <Button
                key={item}
                text={ <>{ item } &nbsp; <img src={ Plus } alt="plus" style={ { transform: 'rotate( 45deg )' } } /></> }
                className='cases__options-button' 
                classNameText='cases__options-button-text' 
                onClick={ () => onClick( item, techs, props.setTechnologies ) } />
            ) ) }
          </div>
        </div>
      }

      { ( projects.length <= 0 && isLoadingCases === false && isLoadingTechnologies === false ) &&
        <NothingFound />
      }

      { ( isLoadingCases || isLoadingTechnologies ) ?
        <>
          <Loader />
        </>
        :
        <>
          <div className="cases__cards">
            { projects.map( ( project ) =>
            {
              const projectTechnologies = technologies?.filter( technology => technology.project_id === project.id )
              const slicedTechnologies = projectTechnologies.slice( 0, 3 )

              return (
                // <Link key={project.title} to={ { pathname: `/portfolio/${ project.id }` } } className="cases__link"
                // >
                <div className="cases__card">
                  <div className="cases__card-title-row">
                    <div className="cases__card-title-column">
                      <div className="cases__card-title-row-2">
                        <div className="cases__card-title">
                          { project.title }
                        </div>
                        <div className="cases__card-technologies">
                          { slicedTechnologies.map( ( technology ) => (
                            <Button key={`${project.title}-${technology.name}`} text={ technology.name } className='cases__technologies-button' classNameText='cases__technologies-button-text' />
                          ) ) }
                        </div>
                      </div>
                      <div className="cases__card-subtitle">
                        { project.description }
                      </div>
                    </div>
                  </div>
                  <div className="cases__image-conteiner">
                    <img src={ project.image_main_url } alt="progect" className="cases__image-main" />
                  </div>
                </div>
              // </Link>
            )
            } ) }
          </div>

          <LetsTalk />
        </>
      }
    </div>
  )
}

export default Cases
