import React, { useState } from 'react'
import { Button } from '@mui/material'

import { ReactComponent as Arrow } from 'images/arrow.svg'
import { ReactComponent as Mail } from 'images/mail.svg'
import { ReactComponent as Tg } from 'images/telegram.svg'
import Ksenia from 'images/ksenia.jpeg'
import UploadButton from 'components/reusable/UploadButton'
import { Close } from '@mui/icons-material'
import InputField from 'components/reusable/InputField/InputField'
import Select from 'components/reusable/Select/Select'

export const CareersForm = ({ data, careers, handleChange, handleFileRemoval, handleSubmit }) => {
  const [isSelectorOpen, setisSelectorOpen] = useState(false)
  return (
    <div className="form__container">
      <div className="form__left-section">
        <h3 className="form__title">Become a part of Milestep team</h3>
        <div className="form__double-field">
          <InputField
            label="Name"
            onChange={handleChange('name')}
            placeholder="Name"
            value={data.name}
            helperColor="yellow"
            helperText={data.error.name && 'Name field must contain at least 2 characters'}
          />
          <InputField
            label="Email"
            onChange={handleChange('email')}
            placeholder="Email"
            value={data.email}
            helperColor="yellow"
            helperText={data.error.email && 'Invalid email'}
          />
        </div>

        <div className="contact__input_wrapper">
          <InputField
            label="Message"
            onChange={handleChange('message')}
            multiline={true}
            placeholder="Message"
            helperColor="yellow"
            value={data.message}
            helperText={data.error.message && 'The message must contain at least 10 characters'}
          />

          <Select
            colorTheme="white"
            data={data}
            handleChange={handleChange('vacancy')}
            isSelectorOpen={isSelectorOpen}
            setisSelectorOpen={setisSelectorOpen}
            menuItems={careers}
          />
        </div>

        <div className="form__files">
          {[...data.files].map((file) => (
            <div className="form__files-file-wrap" key={file.name}>
              <div className="form__files-file">{file.name}</div>
              <Close onClick={() => handleFileRemoval(file.name)} />
            </div>
          ))}
        </div>

        <div className="form__double-field">
          <UploadButton text="UPLOAD CV" handleChange={handleChange} buttonIconColor="white" />
          <Button variant="contained" onClick={handleSubmit}>
            <p>SEND A MESSAGE</p>
            <Arrow fill="black" />
          </Button>
        </div>
      </div>

      <div className="form__right-section">
        <div className="form__right-section-top">
          <h3 className="form__title">Nothing matching your skills?</h3>
          <p className="form__text">
            We&apos;d suggest staying in touch anyway. Send your CV, and we&aposll inform you about the most suitable
            job opportunities ASAP. If you have any other questions, feel free to drop us a line either via Telegram or
            by email.
          </p>
        </div>
        <div className="form__right-section-bottom">
          <img className="form__hr-photo" alt="Kseniia Chernykh" src={Ksenia} draggable={false} />
          <div className="form__hr-info">
            <h3 className="form__hr-name">KSENIIA CHERNYKH</h3>
            <p className="form__hr-position">IT Recruiter</p>
            <div className="form__double-field">
              <a className="form__hr-contact" href="mailto:ksenia@milestep.io">
                <Mail fill="white" />
                Email
              </a>
              <a className="form__hr-contact" href="https://t.me/k_chernykh">
                <Tg fill="white" />
                Telegram
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
