import React from "react"

import Logo from '../../images/Logo.svg'

const Loader = ( props ) =>
{
  return (
    <div className="cases__column-not-found">
      <div className="cases__text-not-found">
        <img src={ Logo } width="60" height="60" alt="logo" />
      </div>
      <div className="cases__text-not-found">
        LOADING...
      </div>
    </div>
  )
}

export default Loader