import React, { useEffect, useState, useRef } from "react"
import { NavLink, Link, useLocation } from 'react-router-dom'
import { Turn as Hamburger } from 'hamburger-react'

import Logo from '../../images/Logo.svg'
import LogoWhite from '../../images/LogoWhite.svg'
import Large from '../../../src/images/Large.svg'
import LargeWhite from '../../../src/images/LargeWhite.svg'
import Menu from "./Menu/Menu"

const NavBar = () =>
{
  const [ isOpen, setOpen ] = useState( false )
  const [ color, setColor ] = useState( false )
  const [ page, setPage ] = useState( false )
  const [ pageName, setPageName ] = useState( '' )
  const [ active, setActive ] = useState(false)

  const location = useLocation()
  const portfolioPage = location.pathname === '/portfolio'

  const navStyles = () => {
    switch (true) {
      case !page:
        return "nav-bar__container nav-bar-dark"
      case portfolioPage:
        return "nav-bar__container nav-bar-grey"
      default:
        return "nav-bar__container"
    }
  }

  const handleMenuToggle = ( isMenuOpen ) =>
  {
    setOpen( isMenuOpen )
  }

  const changeColor = () => {
    if (window.location.pathname === '/') {
      setColor( false )
    }
    else if ( ['/portfolio/', '/staff-augmentation', '/end-to-end', '/magento'  ].includes( window.location.pathname ) ) {
      setColor( false )
    }
    else {
      setColor( true )
    }
  }

  const changePage = () =>
  {
    if ( window.location.pathname === '/' )
    {
      setPage( false )
      setActive( false )
    }
    else if ( window.location.pathname.includes( '/portfolio/' ) )
    {
      setPageName( 'project' )
      setPage( true )
      setActive( false )
    }
    else if ( ['/staff-augmentation', '/end-to-end' , '/magento' ].includes(window.location.pathname))
    {
      setPageName( 'cases' )
      setPage( false )
      setActive(true)
    }
    else
    {
      setPage( true )
      setPageName( 'cases' )
      setActive( false )
    }
  }

  const [opened, setOpened] = useState(false)

  const onClick = () =>
  {
    setOpened(!opened)
  }

  const ref = useRef()
  useOnClickOutside( ref, () => setOpened( false ) )
  function useOnClickOutside ( ref, handler )
  {
    useEffect( () =>
    {
      const listener = ( event ) =>
      {
        if ( !ref.current || ref.current.contains( event.target ) )
        {
          return
        }
        handler( event )
      }
      document.addEventListener( 'mousedown', listener )
      document.addEventListener( 'touchstart', listener )
      return () =>
      {
        document.removeEventListener( 'mousedown', listener )
        document.removeEventListener( 'touchstart', listener )
      }
    }, [ ref, handler ] )
  }

  useEffect( () =>
  {
    changeColor()
    changePage()
  }, [ pageName ] )

  useEffect(() => {
    window.addEventListener('scroll', changeColor)
    window.addEventListener( 'click', changeColor )
    window.addEventListener( 'hashchange', changeColor )

    window.addEventListener( 'scroll', changePage )
    window.addEventListener( 'click', changePage )
    window.addEventListener( 'hashchange', changePage )

    return () => {
      window.removeEventListener('scroll', changeColor)
      window.removeEventListener( 'click', changeColor )
      window.removeEventListener( 'hashchange', changeColor )

      window.removeEventListener( 'scroll', changePage )
      window.removeEventListener( 'click', changePage )
      window.removeEventListener( 'hashchange', changePage )
    }
  }, [])


  return (
    <div className={ isOpen ? 'menuOpened' : '' }>
      <div className={ color ? 'nav-bar__header' : 'nav-bar__header-second' }>
        <div className={ navStyles() }>
          <div className='nav-bar__logoWrap' onClick={ () => handleMenuToggle( false ) }>
            <Link to="/" className='nav-bar__logos'>
              <div className='nav-bar__logo'>
                <img src={ color ? Logo : LogoWhite } width="60" height="60" alt="logo" />
              </div>
              <div className='nav-bar__large'>
                <img src={ color ? Large : LargeWhite } width="158" height="44" alt="large logo" />
              </div>
            </Link>
          </div>

          <div className='nav-bar__navItems'>
            <div ref={ ref } className={ opened ? "nav-bar__test-2" : 'nav-bar__test'}>
              <NavLink
                onClick={onClick}
                className={ 'nav-bar__navItem' + ( ( opened || active ) ? ' nav-bar__active' : '' ) }
              >
                <span className={ color ? 'nav-bar__navLink' : 'nav-bar__navLink-white' }>competence</span>
              </NavLink>
              {color ?
                <>
                  <NavLink
                    to='/staff-augmentation'
                    onClick={ onClick }
                    className={ ( { isActive } ) => 'nav-bar__navItem' }
                    style={ { background: '#F9F9F9', border: '1px solid rgba(17, 21, 23, 0.4)'}}
                  >
                    <span className={ color ? 'nav-bar__navLink' : 'nav-bar__navLink-white' }>Staff augmentation</span>
                  </NavLink>
                  <NavLink
                    to='/end-to-end'
                    onClick={ onClick }
                    className={ ( { isActive } ) => 'nav-bar__navItem' }
                    style={ { background: '#F9F9F9', border: '1px solid rgba(17, 21, 23, 0.4)' } }
                  >
                    <span className={ color ? 'nav-bar__navLink' : 'nav-bar__navLink-white' }>
                      End-to-end software development
                    </span>
                  </NavLink>
                  <NavLink
                    to='/magento'
                    onClick={ onClick }
                    className={ ( { isActive } ) => 'nav-bar__navItem' }
                    style={ { background: '#F9F9F9', border: '1px solid rgba(17, 21, 23, 0.4)' } }
                  >
                    <span className={ color ? 'nav-bar__navLink' : 'nav-bar__navLink-white' }>
                       Magento development services
                    </span>
                  </NavLink>

                </>
              :
                <>
                  <NavLink
                    to='/staff-augmentation'
                    onClick={ onClick }
                    className={ ( { isActive } ) => 'nav-bar__navItem' }
                  >
                    <span className={ color ? 'nav-bar__navLink' : 'nav-bar__navLink-white' }>Staff augmentation</span>
                  </NavLink>
                  <NavLink
                    to='/end-to-end'
                    onClick={ onClick }
                    className={ ( { isActive } ) => 'nav-bar__navItem' }
                  >
                    <span className={ color ? 'nav-bar__navLink' : 'nav-bar__navLink-white' }>
                      End-to-end software development
                    </span>
                  </NavLink>

                  <NavLink
                    to='/magento'
                    onClick={ onClick }
                    className={ ( { isActive } ) => 'nav-bar__navItem' }
                  >
                    <span className={ color ? 'nav-bar__navLink' : 'nav-bar__navLink-white' }>
                      Magento development services
                    </span>
                  </NavLink>
                </>

              }
            </div>
            <NavLink
              to="/portfolio"
              className={({ isActive }) => 'nav-bar__navItem' + (isActive ? ' nav-bar__active' : '')}
            >
              <span className={ color ? 'nav-bar__navLink' : 'nav-bar__navLink-white' }>Portfolio</span>
            </NavLink>
            <NavLink
              to="/pricing"
              className={({ isActive }) => 'nav-bar__navItem' + (isActive ? ' nav-bar__active' : '')}
            >
              <span className={ color ? 'nav-bar__navLink' : 'nav-bar__navLink-white' }>Pricing</span>
            </NavLink>
            <NavLink
              to="/careers"
              className={({ isActive }) => 'nav-bar__navItem' + (isActive ? ' nav-bar__active' : '')}
            >
              <span className={ color ? 'nav-bar__navLink' : 'nav-bar__navLink-white' }>Careers</span>
            </NavLink>
            <NavLink
              to="/blog"
              className={({ isActive }) => 'nav-bar__navItem' + (isActive ? ' nav-bar__active' : '')}
            >
              <span className={ color ? 'nav-bar__navLink' : 'nav-bar__navLink-white' }>Blog</span>
            </NavLink>
            <NavLink
              to="/contact-us"
              className={({ isActive }) => (color ? 'nav-bar__button' : 'nav-bar__button-white') }
            >
              <span className={ color ? 'nav-bar__button-text' : 'nav-bar__button-text-black' }>Contact us</span>
            </NavLink>
          </div>

          {isOpen &&
            <span className='nav-bar__brgButton'>
              <Hamburger
                className='nav-bar__brgButton'
                toggled={ isOpen }
                toggle={ handleMenuToggle }
                size={ 20 }
              />
            </span>
          }

          { !isOpen &&
            <span className={ color ? 'nav-bar__brgButton' : 'nav-bar__brgButton-white' }>
              <Hamburger
                className='nav-bar__brgButton'
                toggled={ isOpen }
                toggle={ handleMenuToggle }
                size={ 20 }
              />
            </span>
          }
        </div>
        <Menu isOpen={ isOpen } toggle={ handleMenuToggle } />
      </div>
    </div>
  )
}

export default NavBar
