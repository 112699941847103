import React, { useState } from 'react'

import { ReactComponent as ChevronRight } from 'images/chevron-right.svg'

const steps = [
  {
    heading: 'DESCRIBE YOUR IDEAS',
    text: 'Understanding your product needs, the required team size, tech stack, and desired outcomes to suggest the best-fit specialists.'
  },
  {
    heading: 'TALENTS SELECTION',
    text: 'If you want to make sure developers are suitable for your corporate culture and follow the same values, weset up interviews with all the candidates.'
  },
  {
    heading: 'TEAM ONBOARDING',
    text: "We also take care of onboarding, thus ensuring the team acts according to your corporate processes and synchronizes with the product's team members."
  },
  {
    heading: 'DELIVERING RESULTS',
    text: 'While developers are focused on providing predictable and smooth-running outcomes, we stay in touch with you to handle administration, all the paperwork, and scaling up or down the team size.'
  }
]

export const StaffAugmentRemoteTeams = () => {
  const [open, setOpen] = useState(false)

  const handleOpen = i => {
    if (window.innerWidth > 1030) return
    if (i === open) setOpen(false)
    else setOpen(i)
  }

  return (
    <section className="staff-augmentation__section sa_remote-teams">
      <div className="staff-augmentation__title">
        <p className="staff-augmentation__title-text">Remote teams</p>
        <p className="staff-augmentation__title-text">setup process</p>
      </div>

      <div className="staff-augmentation__remote-blocks-wrap">
        {steps.map(({ heading, text }, i) => (
          <div
            key={'item_' + i}
            className={'staff-augmentation__remote-block' + (open === i ? ' open' : '')}
            onClick={() => handleOpen(i)}
          >
            <div className="staff-augmentation__remote-header">
              <p className="staff-augmentation__remote-heading">{heading}</p>
              <p className="staff-augmentation__remote-heading">0{i + 1}</p>
              <ChevronRight className="staff-augmentation__remote-heading" />
            </div>
            <p className="staff-augmentation__remote-text">{text}</p>
          </div>
        ))}
      </div>
    </section>
  )
}
