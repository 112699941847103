import axios from 'axios'

const instance = axios.create({
  baseURL:
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEV_URL
      : process.env.REACT_APP_PROD_URL,
  headers: { 'Access-Control-Allow-Origin': '*' }
})

const request = {
  get: (path, params = {}) => instance.get(path, params),
  post: (path, params = {}) => instance.post(path, params)
}

export default request
