import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import api from 'helpers/api'
import { useContactForm } from 'hooks/contactForm'
import Careers from 'components/Careers/Careers'
import { CareersForm } from 'components/Careers/form'
import CareerOffer from 'components/Careers/CareerOffer'

const CareersContainer = () => {
  const { id } = useParams()

  const [careers, setCareers] = useState([])
  const [offer, setOffer] = useState(null)

  const onSubmit = (data) => api.Careers.post(data)
  const { formData, handleChange, handleFileRemoval, handleSubmit } = useContactForm(onSubmit)

  useEffect(() => {
    api.Careers.get()
      .then((r) => setCareers(r.data))
      .catch((e) => console.error(e.message))
  }, [])

  useEffect(() => {
    if (id && careers.length) {
      setOffer(careers.filter((c) => c.slug === id)[0])
    }

    return () => setOffer(null)
  }, [id, careers])

  return (
    <>
      <div className={`careers__wrapper${offer ? ' offer' : ''}`}>
        {offer ? <CareerOffer offer={offer} /> : <Careers careers={careers} />}
      </div>
      <CareersForm
        handleChange={handleChange}
        handleFileRemoval={handleFileRemoval}
        handleSubmit={handleSubmit}
        data={formData}
        careers={careers}
      />
    </>
  )
}

export default CareersContainer
