import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, {useRef, useState} from 'react'
import Slider from "react-slick";
import { NextArrow2, PrevArrow2} from "../reusable/Slick/slider-arrow";
import '../../styles/components/magento/magento-solutions.scss'

const step1 = [
  {
    text: 'ECOMMERCE PLATFORMS',
  },
  {
    text: 'TRADING PORTALS',
  },
  {
    text: 'RETAIL INVENTORY SOFTWARE',
  },
  {
    text: '2BC/B2B MARKETPLACES',
  }
]

const step2 = [
  {
    text: 'MERCHANDISING SOFTWARE',
  },
  {
    text: 'ONLINE AUCTIONS & BIDDING WEBSITES',
  },
  {
    text: 'ONLINE ORDERING SYSTEMS',
  },
  {
    text: 'CRM SOLUTIONS FOR ECOMMERCE',
  }
]

export const MagentoSolutions = () => {
  const sliderRef = useRef()
  const [arrowIndex, setArrowIndex] = useState(0)

  const beforeChange = (_, nextIndex) => setArrowIndex(nextIndex)


  const settings = {
    ref: sliderRef,
    dots: false,
    arrows: false,
    beforeChange,
    infinite: false,
    speed: 500,
    slidesToShow: window.screen.width > 769 ? 2 : 1,
    slidesToScroll: 1,
  };

  const onNext = () => {
    if (!sliderRef.current) return false
    sliderRef.current.slickNext()
  }
  const onPrev = () => {
    if (!sliderRef.current) return false
    sliderRef.current.slickPrev()
  }

  return (
    <section className="magento-solutions__section sa_remote-teams">
      <div className="magento-solutions__title">
        <p className="magento-solutions__title-text">Magento solutions</p>
        <p className="magento-solutions__title-text">we work on</p>
      </div>

      <div className="magento-solutions__arrows">
        <div className="magento-solutions__prev">
          <PrevArrow2 active={arrowIndex === 0} onClick={onPrev} />
        </div>
        <div className="magento-solutions__next">
          <NextArrow2 active={arrowIndex === 1} onClick={onNext} />
        </div>
      </div>


      <Slider {...settings}>
        <div className="magento-solutions__remote-blocks-wrap">
          {step1.map(({text}, i) => (
            <div
              key={'item_1' + i}
              className={'magento-solutions__remote-block'}
              style={{ margin: '0 10px' }}
            >
              <div className="magento-solutions__remote-header">
                <p className="magento-solutions__remote">0{i + 1}</p>
              </div>
              <p className="magento-solutions__remote-text">{text}</p>
            </div>
          ))}
        </div>

        <div className="magento-solutions__remote-blocks-wrap">
          {step2.map(({text}, i) => (
            <div
              key={'item_2' + i}
              className={'magento-solutions__remote-block'}
              style={{ margin: '0 10px' }}
            >
              <div className="magento-solutions__remote-header">
                <p className="magento-solutions__remote">0{i + 5}</p>
              </div>
              <p className="magento-solutions__remote-text">{text}</p>
            </div>
          ))}
        </div>
      </Slider>
    </section>
  )
}


