import React from 'react'

import { TextBlocksWithIcons } from 'components/reusable/TextBlocks/with-icons'
import {
  ETEBusinessesIcon,
  ETECompaniesIcon,
  ETEOrganizationsIcon,
  ETEOwnersIcon,
  ETESeedIcon,
  ETESMEsIcon
} from 'images/EndToEnd'

const texts = [
  'Seed, Series A, and Growth stage startups who are looking for a technical partner',
  'Companies that need an MVP of the product within the budget to receive further funding',
  'SMEs that want to secure, improve, or scale the current functionality of their product',
  "Product owners that have a project idea but you don't know what to start with",
  "Organizations without the app want to enter new markets or automate the industry's processes",
  'Businesses that are about to reduce costs for maintenance of existing software'
]

const icons = [
  <ETESeedIcon />,
  <ETECompaniesIcon />,
  <ETESMEsIcon />,
  <ETEOwnersIcon />,
  <ETEOrganizationsIcon />,
  <ETEBusinessesIcon />
]

export const EndToEndTextBlocks = () => (
  <TextBlocksWithIcons title={['End-to-end development', 'the best works for']} icons={icons} texts={texts} />
)
