import rq from './requestManager'

const api = {
  Careers: {
    get: (id = '') => rq.get(`/api/jobs/${id}`),
    post: form =>
      rq.post('/api/new_request', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
  },

  Cases: {
    get: params => rq.get('/api/cases', params)
  },

  Features: {
    get: params => rq.get('/api/features', params)
  },

  Feedbacks: {
    get: params => rq.get('/api/feedbacks', params)
  },

  Home: {
    get: params => rq.get('/api/home_page', params)
  },

  Project: {
    get: params => rq.get('/api/project/' + params)
  },

  Projects: {
    get: params => rq.get('/api/cases', params)
  },

  Services: {
    get: params => rq.get('/api/services', params)
  },

  Solutions: {
    get: params => rq.get('/api/solutions', params)
  },

  Teammates: {
    get: params => rq.get('/api/team', params)
  },

  Technologies: {
    get: params => rq.get('/api/technologies', params)
  }
}

export default api
