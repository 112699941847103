import React from 'react'
import handleViewport from 'react-in-viewport'

import Button from '../reusable/Button/Button'

const LetsTalk = ({ heading1, heading2, subtext, className }) => {
  const onClick1 = () => window.location.assign('/contact-us')
  const onClick2 = () => window.location.assign('/about')

  const Block = ({ inViewport, forwardedRef, enterCount }) => {
    if (inViewport && enterCount === 1) {
      return (
        <div className="about__lets-talk" ref={forwardedRef}>
          <div className="about__text-talk-container">{heading1 || "LET'S TALK ABOUT YOUR PROJECT"}</div>

          <div className="about__text-talk-container-secondary">{heading2 || "LET'S DISCUSS YOUR IDEAS"}</div>
        </div>
      )
    } else {
      return (
        <div className="about__lets-talk" ref={forwardedRef}>
          <div className="about__text-talk-container-transparent">{heading1 || "LET'S TALK ABOUT YOUR PROJECT"}</div>

          <div className="about__text-talk-container-secondary-transparent">
            {heading2 || "LET'S DISCUSS YOUR IDEAS"}
          </div>
        </div>
      )
    }
  }

  const ViewportBlock = handleViewport(Block)

  return (
    <div className={'about__column-talk-container' + (className ? ` ${className}` : '')}>
      <ViewportBlock />

      <div className="about__row-talk-container">
        <div className="about__talk-container-subtext">
          {subtext ||
            `Drop us a line about your needs or send the project requirements
            (tech stack, the scope of work, timeframes, business idea) so that
            we could provide you with the cooperation proposal`}
        </div>

        <div className="about__button-group">
          <Button
            text="Drop us a line"
            className="about__button-talk-container-group"
            classNameText="about__button-talk-container-text"
            onClick={onClick1}
          />

          <Button
            text="Schedule a call"
            className="about__button-talk-container-secondary-group"
            classNameText="about__button-talk-container-text-secondary"
            onClick={onClick2}
          />
        </div>

        <Button
          text="Drop us a line"
          className="about__button-talk-container"
          classNameText="about__button-talk-container-text"
          onClick={onClick1}
        />

        <Button
          text="Schedule a call"
          className="about__button-talk-container-secondary"
          classNameText="about__button-talk-container-text-secondary"
          onClick={onClick2}
        />
      </div>
    </div>
  )
}

export default LetsTalk
