import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import api from '../helpers/api'
import Project from '../components/Cases/Project/Project'
import { getTechnologies } from '../features/technologiesSlice'
import { getCases } from '../features/casesSlice'
import { getServices } from '../features/servicesSlice'
import { getSolutions } from '../features/solutionsSlice'
import { getFeedbacks } from '../features/feedbacksSlice'
import { getFeatures } from '../features/featuresSlice'
import Loader from 'components/Cases/Loader'

const ProjectContainer = props => {
  const [project, setProject] = useState()

  const { id } = useParams()
  const dispatch = useDispatch()
  const technologies = useSelector(state => state.technologies.technologies)
  const cases = useSelector(state => state.cases.cases)
  const services = useSelector(state => state.services.services)
  const solutions = useSelector(state => state.solutions.solutions)
  const feedbacks = useSelector(state => state.feedbacks.feedbacks)
  const features = useSelector(state => state.features.features)
  const isLoadingCases = useSelector( state => state.cases.isLoading )

  useEffect(() => {
    const getProject = () => {
      try {
        api.Project.get(id).then(resp => {
          setProject(resp)
        })
      } catch (e) {
        console.error(e)
      }
    }
    dispatch(getCases())
    getProject()
    dispatch(getTechnologies())
    dispatch(getServices(id))
    dispatch(getSolutions(id))
    dispatch(getFeedbacks(id))
    dispatch(getFeatures(id))
  }, [dispatch, id])


  if (project && cases && technologies) {
    return (
      <Project
        features={features}
        feedbacks={feedbacks}
        solutions={solutions}
        services={services}
        cases={cases}
        project={project}
        technologies={technologies}
        isLoadingCases={ isLoadingCases }
      />
    )
  }
  else {
    return (
      <div className='cases__wrapper'>
        <div className='project__loader'>
          <Loader />
        </div>
      </div>
    )
  }
}

export default ProjectContainer
