import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Cases from 'components/Cases/Cases'
import { getCases } from 'features/casesSlice'
import { getTechnologies } from 'features/technologiesSlice'

const CasesContainer = () => {
  const [industry, setIndustry] = useState([])
  const [services, setServices] = useState([])
  const [techs, setTechnologies] = useState([])

  const dispatch = useDispatch()
  const { cases } = useSelector(state => state.cases)
  const { technologies } = useSelector(state => state.technologies)
  const isLoadingCases = useSelector(state => state.cases.isLoading)
  const isLoadingTechnologies = useSelector( state => state.technologies.isLoading )

  useEffect(() => {
    let params = [
      { value: industry.toString() },
      { value: services.toString() },
      { value: techs.toString() }
    ]
    dispatch(getCases(params))
    dispatch(getTechnologies())
  }, [dispatch, industry, services, techs])

  if (cases && technologies)
    return (
      <Cases
        technologies={technologies}
        projects={cases}
        industry={industry}
        setIndustry={setIndustry}
        services={services}
        setServices={setServices}
        techs={techs}
        setTechnologies={setTechnologies}
        isLoadingCases={ isLoadingCases }
        isLoadingTechnologies={ isLoadingTechnologies }
      />
    )
}

export default CasesContainer
