import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from 'helpers/api'

const initialState = {
  technologies: [],
  isLoading: null,
  error: {}
}

export const getTechnologies = createAsyncThunk(
  'technologies/getTechnologies',
  async project_id => {
    const res = await api.Technologies.get({ params: { id: project_id } })
    return res.data
  }
)

export const technologiesSlice = createSlice({
  name: 'technologies',
  initialState,
  reducers: {
    fetchTechnologiesSuccess(state, action) {
      state.technologies = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getTechnologies.fulfilled, (state, action) => {
      state.technologies = action.payload
      state.isLoading = false
    })
    builder.addCase(getTechnologies.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getTechnologies.rejected, state => {
      state.isLoading = false
      state.error = 'error'
    })
  }
})

export const { fetchTechnologiesSuccess } = technologiesSlice.actions
export default technologiesSlice.reducer
