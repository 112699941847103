import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from 'helpers/api'

const initialState = {
  solutions: [],
  isLoading: null,
  error: {}
}

export const getSolutions = createAsyncThunk(
  'solutions/getSolutions',
  async project_id => {
    const res = await api.Solutions.get({ params: { id: project_id } })
    return res.data
  }
)

export const solutionsSlice = createSlice({
  name: 'solutions',
  initialState,
  reducers: {
    fetchSolutionsSuccess(state, action) {
      state.solutions = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getSolutions.fulfilled, (state, action) => {
      state.solutions = action.payload
      state.isLoading = false
    })
    builder.addCase(getSolutions.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getSolutions.rejected, state => {
      state.isLoading = false
      state.error = 'error'
    })
  }
})

export const { fetchSolutionsSuccess } = solutionsSlice.actions
export default solutionsSlice.reducer
