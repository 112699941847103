import React, { useEffect } from 'react'

import { ContactUsForm } from 'components/ContactUs/form'
import { useContactForm } from 'hooks/contactForm'
import rq from 'helpers/requestManager'
import { isEmpty } from 'lodash'

export const StaffAugmentDevsAvailableForm = ({ devsData }) => {
  const onSubmit = data => {
    Object.keys(devsData).forEach(key => {
      if (['error', 'service'].includes(key) || isEmpty(devsData[key])) return

      if (key === 'developers') {
        devsData.developers.forEach(dev => data.append('developers[]', JSON.stringify(dev)))
        return
      }

      data.append(key, devsData[key])
    })

    if (formData.message) {
      data.delete('comment')
      data.append('message', formData.message)
    }

    rq.post('/api/developer_request', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  const { formData, handleChange, handleFileRemoval, handleSubmit } = useContactForm(onSubmit)

  return (
    <ContactUsForm
      handleChange={handleChange}
      handleFileRemoval={handleFileRemoval}
      handleSubmit={handleSubmit}
      data={formData}
      devs
    />
  )
}
