import React from 'react'

import { StaffAugmentTextBlocks } from './text-blocks'
import { StaffAugmentRemoteTeams } from './remote-teams'
import { StaffAugmentTopSection } from './top-section'
import { StaffAugmentDevsAvailable } from './devs-available'
import { StaffAugmentTechnologies } from './technologies'
import { StaffAugmentBenefits } from './benefits'
import { StaffAugmentTalents } from './talents'
import LetsTalk from 'components/LetsTalk/LetsTalk'

export const StaffAugmentation = () => {
  return (
    <>
      <StaffAugmentTopSection />
      <StaffAugmentTextBlocks />
      <StaffAugmentRemoteTeams />
      <StaffAugmentDevsAvailable />
      <StaffAugmentBenefits />
      <StaffAugmentTechnologies />
      <StaffAugmentTalents />
      <LetsTalk
        className="sa_hire-specialists"
        heading1="HIRE SPECIALISTS WITH"
        heading2="THE DESIRABLE DOMAIN EXPERTISE"
        subtext="Kindly give us the details about the required professionals,
                 your project details, and your business goals so we can
                 promptly provide relevant candidates. You can also let us know
                 if you need additional help scoping and outlining your project"
      />
    </>
  )
}
