import { configureStore } from '@reduxjs/toolkit'

import cases from 'features/casesSlice'
import features from 'features/featuresSlice'
import feedbacks from 'features/feedbacksSlice'
import services from 'features/servicesSlice'
import solutions from 'features/solutionsSlice'
import technologies from 'features/technologiesSlice'
import homeSlice from 'features/homeSlice'

export const store = configureStore({
  reducer: {
    cases,
    features,
    feedbacks,
    services,
    solutions,
    technologies,
    home: homeSlice,
  }
})
