import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { FacebookShareButton, LinkedinShareButton } from 'react-share'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import dayjs from 'dayjs'

import CareerList from './CareerList'
import { ReactComponent as LinkedInIcon } from 'images/Home/Linkedin.svg'
import { ReactComponent as FacebookIcon } from 'images/Home/Facebook.svg'
import { ReactComponent as LinkIcon } from 'images/link.svg'

const CareerOffer = ({ offer }) => (
  <>
    <div className="careers__action-wrap">
      <div className="careers__action-block">
        <Link to={'#'} className="careers__action-button-wrap">
          <div className="careers__action-button">
            <span>apply now</span>
            <ArrowForwardIcon />
          </div>
        </Link>

        <hr className="careers__action-hr" />

        <div className="careers__action-share">
          <span>Share vacancy</span>
          <div className="careers__action-links">
            <LinkedinShareButton
              title="Milestep"
              summary="Join the Milestep team"
              url={window.location.href}
            >
              <LinkedInIcon />
            </LinkedinShareButton>
            <FacebookShareButton
              url={window.location.href}
              hashtag={'#milestep'}
              quote="Join the Milestep team"
            >
              <FacebookIcon />
            </FacebookShareButton>
            <LinkIcon
              className="react-share__ShareButton"
              onClick={() =>
                navigator.clipboard.writeText(`${window.location.href}`)
              }
            />
          </div>
        </div>
      </div>
    </div>

    <div className="careers__offer-content">
      <div className="careers__offer-header">
        <h3 className="careers__offer-title">{offer.title}</h3>
        <div className="careers__offer-subtitle">
          <p className="careers__offer-location">{offer.location}</p>
          <p className="careers__offer-time">
            {dayjs(offer.updated_at).format('MMMM D, YYYY')}
          </p>
        </div>
        <p className="careers__offer-description">{offer.description}</p>
      </div>

      {offer.requirements.length > 0 && (
        <CareerList
          items={offer.requirements.map(rq => rq.requirement)}
          title="Requirements"
        />
      )}
      {offer.responsibilities.length > 0 && (
        <CareerList
          items={offer.responsibilities.map(rs => rs.responsibility)}
          title="Responsibilities"
        />
      )}
      {offer.our_offers.length > 0 && (
        <CareerList
          items={offer.our_offers.map(ofr => ofr.our_offer)}
          title="We Offer"
        />
      )}
    </div>
  </>
)

export default memo(CareerOffer)
