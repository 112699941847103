import { useRef } from 'react'

import { ReactComponent as File } from 'images/file.svg'
import { Button } from '@mui/material'

const UploadButton = ({ text, handleChange, buttonIconColor }) => {
  const fileInputRef = useRef(null)

  const handleClick = () => fileInputRef.current.click()

  return (
    <>
      <Button variant="outlined" onClick={handleClick}>
        <p>{text}</p>
        <File fill={buttonIconColor} />
      </Button>

      <input
        type="file"
        multiple
        onChange={handleChange('files')}
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
    </>
  )
}

export default UploadButton
