import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from 'helpers/api'

const initialState = {
  home: [],
  isLoading: null,
  error: {}
}

export const getHome = createAsyncThunk('home/getHome', async () => {
  const res = await api.Home.get()
  return res.data
})

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    fetchHomeSuccess(state, action) {
      state.home = action.payload
    }
  },
  extraReducers: {
    [getHome.fulfilled]: (state, action) => {
      state.home = action.payload
      state.isLoading = false
    },
    [getHome.pending]: state => {
      state.isLoading = true
    },
    [getHome.rejected]: state => {
      state.isLoading = false
      state.error = 'error'
    }
  }
})

export const { fetchHomeSuccess } = homeSlice.actions
export default homeSlice.reducer
