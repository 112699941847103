import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from 'helpers/api'

const initialState = {
  cases: [],
  isLoading: null,
  error: {}
}

export const getCases = createAsyncThunk('cases/getCases', async params => {
  const res = await api.Cases.get({
    params: params
      ? {
          industry: params[0].value,
          services: params[1].value,
          technologies: params[2].value
        }
      : null
  })
  return res.data
})

export const casesSlice = createSlice({
  name: 'cases',
  initialState,
  reducers: {
    fetchCasesSuccess(state, action) {
      state.cases = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getCases.fulfilled, (state, action) => {
      state.cases = action.payload
      state.isLoading = false
    })
    builder.addCase(getCases.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getCases.rejected, state => {
      state.isLoading = true
      state.error = 'error'
    })
  }
})

export const { fetchCasesSuccess } = casesSlice.actions
export default casesSlice.reducer
