import React from "react"
import Logo from '../../../images/LogoRed.svg'

const Loader = ( props ) =>
{
  return (
    <div className='about__loader'>
      <img src={ Logo } alt="about" />
    </div>
  )
}

export default Loader
