import React from 'react';
import HomeLogo1 from "../../../images/Home/HomeLogo1.svg";
import HomeLogo2 from "../../../images/Home/HomeLogo2.svg";
import HomeLogo3 from "../../../images/Home/HomeLogo3.svg";
import HomeLogo4 from "../../../images/Home/HomeLogo4.svg";
import HomeLogo5 from "../../../images/Home/HomeLogo5.svg";
import HomeLogo6 from "../../../images/Home/HomeLogo6.svg";
import HomeLogo7 from "../../../images/Home/HomeLogo7.svg";

function FireAnimationDesktop() {
  return (
    <div className="about__column-main-second">
      <div className="about__div-image-1">
        <img src={ HomeLogo1 } alt="about" className="about__main-image-1" />
      </div>
      <div className="about__div-image-2">
        <img src={ HomeLogo2 } alt="about" className="about__main-image-1" />
      </div>
      <div className="about__div-image-3">
        <img src={ HomeLogo3 } alt="about" className="about__main-image-1" />
      </div>
      <div className="about__div-image-4">
        <img src={ HomeLogo4 } alt="about" className="about__main-image-1" />
      </div>
      <div className="about__div-image-5">
        <img src={ HomeLogo5 } alt="about" className="about__main-image-1" />
      </div>
      <div className="about__div-image-6">
        <img src={ HomeLogo6 } alt="about" className="about__main-image-1" />
      </div>
      <div className="about__div-image-7">
        <img src={ HomeLogo7 } alt="about" className="about__main-image-1" />
      </div>
      <div className="about__div-image-8">
        <img src={ HomeLogo2 } alt="about" className="about__main-image-1" />
      </div>
    </div>
  );
}

export default FireAnimationDesktop;