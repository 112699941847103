import React, { useEffect, useState } from 'react'

import Background from '../../../images/Home/Background.svg'
import Expand from "../Expand/Expand"
import Button from '../../reusable/Button/Button'

import Arrow from "../../../images/Cases/arrow.svg"

const Features = ( { features, image } ) =>
{
  const [ expanded, setExpanded ] = React.useState( [ true, false, false, false, false, false, false, false ] )
  const [ opened, setOpened ] = useState( 0 )

  const onClickNext = () => {
    if (opened === 6) {
      setOpened( 0 )
    }
    else {
      setOpened( opened + 1 )
    }
  }

  const onClickPrivious = () =>
  {
    if ( opened === 0 )
    {
      setOpened( 6 )
    }
    else
    {
      setOpened( opened - 1 )
    }
  }

  useEffect( () =>
  {
    setExpanded( arr => [ true, false, false, false, false, false, false, false ] )
  }, [ opened ] )

  return (
    <>
      <div className="project__block-six">
        <div className="project__features-wrapper">
          <div className="project__features">
            <div className="project__features-row">
              <div className="project__features-title">
                Implemented features
              </div>
              <div className="project__features-buttons">
                <Button
                  text={ <><img src={ Arrow } alt='arrow' className='project__features-arrow-1'/></>}
                  className='project__features-button'
                  onClick={ onClickPrivious } />
                <Button
                  text={ <><img src={ Arrow } alt='arrow' className='project__features-arrow-2' /></> }
                  className='project__features-button'
                  onClick={ onClickNext } />
              </div>
            </div>
            <div className="project__features-row-second">
              <div className="project__features-dropdowns">
                { features.map( ( item, index ) => (
                  <Expand title={ item.title } content={ item.content } setOpened={ setOpened } notLast={ features.length } op={ opened } opened={ expanded } id={ index } setExpand={ setExpanded } />
                ) ) }
              </div>

              { features.map( ( item, index ) => (
                <>
                  { opened === index &&
                    <div className="project__features-image-block" style={ { backgroundImage: `url("${ item.image_url }")` } } />
                  }
                </>
              ) ) }
            </div>
          </div>
        </div>

        <div>
          <img src={ Background } alt="about" className="project__div-image" />
        </div>
      </div>

      <div className="project__block-seven">
        { features.map( ( item, index ) => (
          <>
            { opened === index &&
              <img src={ item.image_url } alt="progect" className="project__features-image-2" />
            }
          </>
        ) ) }
      </div>
    </>
  )
}

export default Features
