import React from 'react'
import { Link } from 'react-router-dom'

import s from './Footer.module.css'
import Facebook from '../../images/Home/Facebook.svg'
import Git from '../../images/Home/GIT.svg'
import Linkedin from '../../images/Home/Linkedin.svg'
import Instagram from '../../images/Home/Instagram.svg'
import Twitter from '../../images/Home/Twitter.svg'
import Milestep from '../../images/Home/Milestep.svg'
import BE from '../../images/Home/be.svg'

const Footer = () =>
{
  return (
    <div className={ s.footerContainer }>
      <div className={ s.title }>
        <img src={ Milestep } width="112" height="30" alt="milestep" />
      </div>
      <div className={ s.info }>
        <div className={ s.infoBoxes }>
          <div className={ s.infoBox }>
            <span className={ s.infoTitle }>Delaware</span>
            <ul>
              <li>
                <span>MileStep LLC,</span>
              </li>
              <li>
                <span>8 The Green, Suite A, Dover</span>
              </li>
              <li>
                <span>USA, Delaware, 19901</span>
              </li>
            </ul>
          </div>

          <div className={ s.infoBox }>
            <span className={ s.infoTitle }>Cherkasy</span>
            <ul>
              <li>
                <span>MileStep LLC,</span>
              </li>
              <li>
                <span>42/1, Priportova St.</span>
              </li>
              <li>
                <span>Cherkasy, Ukraine</span>
              </li>
            </ul>
          </div>

          <div className={ s.infoBox }>
            <span className={ s.infoTitle }>Uman</span>
            <ul>
              <li>
                <span>MileStep LLC,</span>
              </li>
              <li>
                <span>11, Nebesnoi Sotni St.</span>
              </li>
              <li>
                <span>Uman, Ukraine</span>
              </li>
            </ul>
          </div>

          <div className={ s.infoBox }>
            <span className={ s.infoTitle }>Kraków</span>
            <ul>
              <li>
                <span>MileStep LLC,</span>
              </li>
              <li>
                <span>Wadowicka 7, 30-437</span>
              </li>
              <li>
                <span>Kraków, Poland</span>
              </li>
            </ul>
          </div>

          <div className={ s.infoBox }>
            <span className={ s.infoTitle }>Discover Milestep</span>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/end-to-end">End-to-end development</Link>
              </li>
              <li>
                <Link to="/staff-augmentation">Staff augmentation</Link>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/portfolio">Portfolio</Link>
              </li>
              <li>
                <Link to="/careers">Careers</Link>
              </li>
            </ul>
          </div>
          <div className={ s.infoBox2 }>
            <div className={s.row}>
              <a href="https://www.linkedin.com/company/milestep">
                <img src={ Linkedin } alt="linkedin" />
              </a>
              <a href="https://www.facebook.com/MileStepOutsourcing">
                <img src={ Facebook } alt="facebook" />
              </a>
              <a href="https://github.com/milestep">
                <img src={ Git } alt="git" />
              </a>
            </div>
            <div className={ s.row }>
              <a href="https://www.instagram.com/milestep.io/">
                <img src={ Instagram } alt="instagram" />
              </a>
              <a href="https://twitter.com/milestepcompany">
                <img src={ Twitter } alt="twitter" />
              </a>
              <a href="https://www.behance.net/milestepdesign">
                <img src={ BE } alt="be" />
              </a>
            </div>
          </div>
        </div>

        <div className={ s.imgLinks }>
          <a href="https://www.linkedin.com/company/milestep">
            <img src={ Linkedin } alt="linkedin" />
          </a>
          <a href="https://www.facebook.com/MileStepOutsourcing">
            <img src={ Facebook } alt="facebook" />
          </a>
          <a href="https://github.com/milestep">
            <img src={ Git } alt="git" />
          </a>
          <a href="https://www.instagram.com/milestep.io/">
            <img src={ Instagram } alt="instagram" />
          </a>
          <a href="https://twitter.com/milestepcompany">
            <img src={ Twitter } alt="twitter" />
          </a>
          <a href="https://www.behance.net/milestepdesign">
            <img src={ BE } alt="be" />
          </a>
        </div>
      </div>
      <hr className={ s.hrGrey } />
      <div className={ s.bottomItems }>
        <span>All rights reserved.</span>
        <span>© Copyright 2023 Milestep</span>
      </div>
    </div>
  )
}

export default Footer
