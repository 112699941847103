import React, { memo } from 'react'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import { Link } from 'react-router-dom'
import { useSnapCarousel } from 'react-snap-carousel'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Button } from '@mui/material'

const width = window.innerWidth
const CARD_WIDTH =
  width < 768 ? width - 48 : width < 1280 ? width / 2 - 48 : width / 3 - 80

const Careers = ({ careers }) => {
  const hideButtons = window.innerWidth < careers.length * CARD_WIDTH
  const { scrollRef, next, prev, activePageIndex, pages } = useSnapCarousel()

  return (
    <div className="careers__content">
      <div className="careers__header">
        <h2 className="careers__title">Careers</h2>
        <p className="careers__subtitle">
          We have neither hierarchy nor bureaucracy because our cooperation is
          based on responsibility and openness. We believe that soft skills and
          dedication values more than a technical background. We create an
          environment that helps your professional growth, and we know that
          like-minded colleagues will facilitate you to reach the peaks faster.
        </p>
      </div>
      <div className="careers__offers-wrap">
        {careers.length > 0 && (
          <>
            <div className="careers__offers" ref={scrollRef}>
              {careers.map(c => (
                <Link
                  key={Math.random()}
                  className="careers__card"
                  to={`/careers/${c.slug}`}
                >
                  <div className="careers__card-header">
                    <h1 className="careers__card-title">{c.title}</h1>
                    <div className="careers__card-link">
                      <ArrowOutwardIcon htmlColor="black" />
                    </div>
                  </div>
                  <ul className="careers__card-responsibilities">
                    {c.responsibilities.map(({ responsibility }) => (
                      <li key={Math.random()}>{responsibility}</li>
                    ))}
                  </ul>
                </Link>
              ))}
            </div>
            {hideButtons && (
              <div className="careers__card-controls">
                <Button
                  variant="outlined"
                  onClick={prev}
                  disabled={activePageIndex === 0}
                >
                  <ArrowBackIosNewIcon />
                </Button>
                <Button
                  variant="outlined"
                  onClick={next}
                  disabled={activePageIndex === pages.length - 1}
                >
                  <ArrowForwardIosIcon />
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default memo(Careers)
