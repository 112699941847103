import * as React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useEffect, useRef } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const ExpandMore = styled( ( props ) =>
{
  const { expand, ...other } = props;
  return <IconButton { ...other } />;
} )( ( { theme, expand } ) => ( {
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create( 'transform', {
    duration: theme.transitions.duration.shortest,
  } ),
} ) );

const Expand = ( { title, content, notLast, opened, id, setExpand, op, setOpened } ) =>
{
  const [ expanded, setExpanded ] = React.useState( opened[id] )

  const handleExpandClick = () =>
  {
    if (expanded === false) {
      setOpened(id)
    }
    setExpanded( !expanded );
  }

  const ref = useRef()
  useOnClickOutside( ref, () => setExpanded( false ) )
  function useOnClickOutside ( ref, handler )
  {
    useEffect( () =>
    {
      const listener = ( event ) =>
      {
        if ( !ref.current || ref.current.contains( event.target ) )
        {
          return
        }
        handler( event )
      }
      document.addEventListener( 'mousedown', listener )
      document.addEventListener( 'touchstart', listener )
      return () =>
      {
        document.removeEventListener( 'mousedown', listener )
        document.removeEventListener( 'touchstart', listener )
      }
    }, [ ref, handler ] )
  }

  useEffect( () =>
  {
    if (id === op) {
      setExpanded( true )
    }
    else {
      setExpanded( false )
    }
  }, [ op ] )

  return (
    <>
      <label ref={ ref } for={ title } className={ expanded ? "project__features-dropdowns-column-2" : "project__features-dropdowns-column" }>
        <div className="project__features-dropdowns-row">
          <div className='project__features-dropdowns-title'>
            <>0.{ id + 1 }&nbsp;&nbsp;{ title }</>
          </div>
          <ExpandMore
            expand={ expanded }
            onClick={ handleExpandClick }
            aria-expanded={ expanded }
            aria-label="show more"
            id={ title }
          >
            <KeyboardArrowDownIcon onClick={ handleExpandClick } sx={ { color: 'white' } } />
          </ExpandMore>
        </div>
        { expanded &&
          <div className="project__features-dropdowns-row-text">
            <div className='project__features-dropdowns-text'>
              { content }
            </div>
          </div>
        }
      </label>
      <hr className="project__hr-2" />
    </>
  )
}

export default Expand