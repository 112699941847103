import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from 'helpers/api'

const initialState = {
  features: [],
  isLoading: null,
  error: {}
}

export const getFeatures = createAsyncThunk(
  'features/getFeatures',
  async project_id => {
    const res = await api.Features.get({
      params: { id: project_id }
    })
    return res.data
  }
)

export const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    fetchFeaturesSuccess(state, action) {
      state.features = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getFeatures.fulfilled, (state, action) => {
      state.features = action.payload
      state.isLoading = false
    })
    builder.addCase(getFeatures.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getFeatures.rejected, state => {
      state.isLoading = false
      state.error = 'error'
    })
  }
})

export const { fetchFeaturesSuccess } = featuresSlice.actions
export default featuresSlice.reducer
