import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import React, {useRef, useState} from 'react';
import '../../styles/components/magento/magento-manage.scss'
import {ReactComponent as One} from 'images/MagentoDevelopment/01.svg'
import {ReactComponent as Two} from 'images/MagentoDevelopment/02.svg'
import {ReactComponent as Bullet} from 'images/MagentoDevelopment/Bullet.svg'
import {NextArrow, PrevArrow} from "../reusable/Slick/slider-arrow";


export const MagentoManage = () => {


  const sliderRef = useRef()
  const [arrowIndex, setArrowIndex] = useState(0)
  const beforeChange = (_, nextIndex) => setArrowIndex(nextIndex)

  const settings = {
    ref: sliderRef,
    dots: false,
    arrows: false,
    beforeChange,
    infinite: false,
    speed: 500,
    slidesToShow: window.screen.width > 363 ? 2 : 1,
    slidesToScroll: 1,
  };

  const onNext = () => {
    if (!sliderRef.current) return false
    sliderRef.current.slickNext()
  }
  const onPrev = () => {
    if (!sliderRef.current) return false
    sliderRef.current.slickPrev()
  }

  return (
    <section className="magento-manage__section">
      <div className="magento-manage__title">Manage your team in a suitable way</div>

      <div className="magento-manage__arrows">
        <div className="magento-manage__prev">
          <PrevArrow active={arrowIndex === 0} onClick={onPrev}/>
        </div>
        <div className="magento-manage__next">
          <NextArrow active={arrowIndex === 1} onClick={onNext}/>
        </div>
      </div>


      <div className="magento-manage__main">
        <Slider {...settings}>

          <div className="magento-manage__left">
            <div className="magento-manage__img">
              <One/>
            </div>

            <div className="magento-manage__block-left">
              <div className="magento-manage__block-dev">
                <div className="magento-manage__dev">FULL-CYCLE DEVELOPMENT</div>
                <div className="magento-manage__dev-text">Entrust your project to the software Development team</div>
              </div>

              <div className="magento-manage__block-text">
                <li className="magento-manage__text"><Bullet/>Scope of work</li>
                <li className="magento-manage__text"><Bullet/>Project management</li>
                <li className="magento-manage__text"><Bullet/>Product logo & branding</li>
                <li className="magento-manage__text"><Bullet/>UI/UX design</li>
                <li className="magento-manage__text"><Bullet/>Product & data architecture</li>
                <li className="magento-manage__text"><Bullet/>Software development</li>
                <li className="magento-manage__text"><Bullet/>Manual & automated testing</li>
                <li className="magento-manage__text"><Bullet/>CI/CD setup</li>
                <li className="magento-manage__text"><Bullet/>Product launch</li>
                <li className="magento-manage__text"><Bullet/>Further maintenance</li>
              </div>
            </div>
          </div>

          <div className="magento-manage__right">
            <div className="magento-manage__img">
              <Two/>
            </div>

            <div className="magento-manage__block-right">
              <div className="magento-manage__block-dev2">
                <div className="magento-manage__dev">REMOTE TEAMS</div>
                <div className="magento-manage__dev-text">Hire professional with relevant skills and expertise</div>
              </div>

              <div className="magento-manage__block-text2">
                <li className="magento-manage__text"><Bullet/>Recruitment process</li>
                <li className="magento-manage__text"><Bullet/>Developers` pre-screening</li>
                <li className="magento-manage__text"><Bullet/>Setting up the interview with candidates</li>
                <li className="magento-manage__text"><Bullet/>Developers onboarding</li>
                <li className="magento-manage__text"><Bullet/>All the paperwork management
                  (hardware,accounting,administration,HR operations)
                </li>
                <li className="magento-manage__text"><Bullet/>Managing legal and security-related docs</li>
                <li className="magento-manage__text"><Bullet/>Team scaling</li>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}

