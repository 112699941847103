import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as ChevronRight } from 'images/chevron-right.svg'

export const TextBlocksWithIcons = ({ title, icons, texts }) => {
  const [pos, setPos] = useState(0)

  const blockWidth = window.innerWidth * 0.9 + 8

  const prevBlock = () => {
    if (pos > 0) setPos(ps => ps - 1)
  }
  const nextBlock = () => {
    if (pos < 5) setPos(ps => ps + 1)
  }

  return (
    <section className="staff-augmentation__section">
      <div className="staff-augmentation__title sa_text-blocks">
        <p className="staff-augmentation__title-text">{title[0]}</p>
        <p className="staff-augmentation__title-text">{title[1]}</p>
      </div>
      <div className="staff-augmentation__text-blocks-wrap">
        <div className="staff-augmentation__text-blocks" style={{ transform: `translateX(-${pos * blockWidth}px)` }}>
          {texts.map((tb, i) => (
            <div className="staff-augmentation__text-block" key={'SATextBlock_' + i}>
              <div className="d-flex">{icons[i]}</div>
              <p className="staff-augmentation__text-block-p">{tb}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="staff-augmentation__text-blocks-buttons">
        <button className="staff-augmentation__text-blocks-button" onClick={prevBlock} disabled={pos === 0}>
          <ChevronRight />
        </button>
        <button className="staff-augmentation__text-blocks-button" onClick={nextBlock} disabled={pos === 5}>
          <ChevronRight />
        </button>
      </div>
    </section>
  )
}

TextBlocksWithIcons.propTypes = {
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  icons: PropTypes.arrayOf(PropTypes.element).isRequired,
  texts: PropTypes.arrayOf(PropTypes.string).isRequired
}
