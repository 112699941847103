import React from 'react'
import { FormControl, InputLabel, MenuItem, Select as MuiSelect, ThemeProvider, createTheme } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { ReactComponent as Plus } from 'images/Cases/Plus.svg'

const Select = ({ label, isSelectorOpen, setisSelectorOpen, menuItems, data, handleChange, colorTheme }) => {
  const blackColorTheme = colorTheme === 'black'
  const whiteColorTheme = colorTheme === 'white'

  const whiteTheme = createTheme({
    palette: {
      primary: {
        main: '#fff',
      },
    },
  })

  const blackTheme = createTheme({
    palette: {
      primary: {
        main: '#000',
      },
    },
  })

  const selectStyles = {
    '& fieldset': {
      borderBottom: isSelectorOpen ? 'none' : '2px solid black',
      borderBottomLeftRadius: isSelectorOpen ? 'unset' : '4px',
      borderBottomRightRadius: isSelectorOpen ? 'unset' : '4px',
    },
  }

  const SelectIcon = () => {
    const iconStyles = {
      transform: isSelectorOpen ? 'rotate(45deg)' : 'rotate(0)',
      marginRight: '25px',
      marginTop: '10px',
      height: '30px',
      width: '30px',
      position: 'absolute',
      top: '0',
      right: '0',
      display: 'inline-block',
      pointerEvents: 'none',
    }
    return <Plus style={{ ...iconStyles, color: whiteColorTheme ? '#fff' : '#000' }} />
  }

  const menuProps = {
    PaperProps: {
      className: whiteColorTheme ? 'contact__vacancy-paper contact__white' : 'contact__vacancy-paper',
    },
  }

  const renderValue = (selected) => {
    if (selected.length === 0) {
      return <em style={{ fontStyle: 'normal' }}>VACANCY</em>
    }
    return selected.join(', ').toUpperCase()
  }

  const menuItemStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    '&.Mui-selected': {
      backgroundColor: blackColorTheme ? 'white' : 'transparent',
    },
    '&.Mui-selected:hover': {
      backgroundColor: blackColorTheme ? 'white' : 'transparent',
    },
    '&.Mui-focusVisible': {
      backgroundColor: blackColorTheme ? 'white' : 'transparent',
    },
  }
  return (
    <FormControl fullWidth>
      <InputLabel id="vacancy-label">{label}</InputLabel>
      <ThemeProvider theme={whiteTheme}>
          <MuiSelect
            sx={{ ...selectStyles }}
            onOpen={() => setisSelectorOpen(true)}
            onClose={() => setisSelectorOpen(false)}
            open={isSelectorOpen}
            labelId={`${label}-label`}
            label={label}
            multiple
            displayEmpty
            IconComponent={() => <SelectIcon />}
            value={data.vacancy || []}
            onChange={handleChange}
            renderValue={renderValue}
            MenuProps={menuProps}
          >
            {menuItems && menuItems.length > 0 ? (
              menuItems.map((menuItem) => (
                <MenuItem
                  key={menuItem.slug}
                  value={menuItem.slug}
                  sx={{ ...menuItemStyles }}
                >
                  {menuItem.title}
                  <ThemeProvider theme={whiteColorTheme ? whiteTheme : blackTheme}>
                    <Checkbox
                      sx={{ color: whiteColorTheme ? 'white' : 'black' }}
                      checked={data.vacancy.indexOf(menuItem.slug) > -1}
                    />
                  </ThemeProvider>
                </MenuItem>
              ))
            ) : (
              <MenuItem
                disabled
                sx={{ color: 'black' }}
              >
                At the moment we don't have any open vacancies
              </MenuItem>
            )}
          </MuiSelect>
      </ThemeProvider>
    </FormControl>
  )
}

export default Select
