import React, { useEffect, useState } from 'react'

import { serviceStepsMulti } from './steps-data'
import InputField from 'components/reusable/InputField/InputField'

import { ReactComponent as Minus } from 'images/minus.svg'
import { ReactComponent as Plus } from 'images/Cases/Plus.svg'

const initialDeveloper = {
  level: ''
}

const resetError = {
  stack: null,
  services: null
}

export const StaffAugmentDevsAvailableOptionsMulti = ({ step, setState, state }) => {
  const [count, setCount] = useState({ idc: 0 })
  const { title, options } = serviceStepsMulti[step]

  const addDeveloper = service => {
    setState(ps => {
      const { developers } = ps
      developers.push({ ...initialDeveloper, service: service, dId: count.idc + 1 })

      return {
        ...ps,
        developers,
        error: resetError
      }
    })
    setCount(ps => ({ ...ps, idc: ps.idc + 1, [service]: ps[service] + 1 || 1 }))
  }
  const removeDeveloper = service => {
    const { developers } = state
    developers.splice(
      developers.findIndex(d => d.service === service),
      1
    )

    setState(ps => ({
      ...ps,
      developers
    }))

    setCount(ps => ({ ...ps, [service]: ps[service] - 1 }))
  }

  const setLevel = (dId, level) => {
    const { developers } = state
    const dev = state.developers.findIndex(d => d.dId === dId)

    developers[dev].level = level

    setState(ps => ({
      ...ps,
      developers
    }))
  }

  const setStack = ({ target: { value } }) => {
    setState(ps => ({
      ...ps,
      stack: value,
      error: resetError
    }))
  }

  const setStage = product_stage => {
    setState(ps => ({
      ...ps,
      product_stage,
      error: resetError
    }))
  }

  const groupedDevs =
    step === 3
      ? state.developers.reduce((acc, obj) => {
          const key = obj.service
          const curGroup = acc[key] ?? []

          return { ...acc, [key]: [...curGroup, obj] }
        }, {})
      : null

  return (
    <div className="staff-augmentation__devs-multi">
      <div className="staff-augmentation__title">
        <p className="staff-augmentation__title-text">{title}</p>
      </div>

      {step === 2 && (
        <div className="staff-augmentation__devs-services-wrap sa_multi-services">
          {options.map(option => (
            <div className="staff-augmentation__devs-multi-service-wrap" key={'sa_devs_opt-' + option}>
              {option}

              <div className={`staff-augmentation__devs-service${count[option] ? ' selected' : ''}`}>
                <button
                  className="staff-augmentation__devs-multi-button"
                  type="button"
                  onClick={() => removeDeveloper(option)}
                  disabled={!count[option]}
                >
                  <Minus />
                </button>
                {count[option] || 0}
                <button
                  className="staff-augmentation__devs-multi-button"
                  type="button"
                  onClick={() => addDeveloper(option)}
                >
                  <Plus />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {step === 3 &&
        Object.keys(groupedDevs).map(key =>
          groupedDevs[key].map((dev, i) => (
            <div className="sa_multi-levels" key={'sa_devs_multi-level-' + key + i}>
              {key}#{i + 1}
              <div className="staff-augmentation__devs-services-wrap">
                {options[key].map(option => (
                  <div
                    key={'sa_dev_opt-' + option}
                    className={`staff-augmentation__devs-service${dev.level.includes(option) ? ' selected' : ''}`}
                    onClick={() => setLevel(dev.dId, option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            </div>
          ))
        )}

      {step === 4 && (
        <div className="sa_devs-multi-needs">
          <p className="sa_devs-multi-needs-text">What tech stack do you use on your project?</p>
          <InputField
            label="Message"
            color="black"
            onChange={setStack}
            multiline={true}
            placeholder="Message"
            helperColor={getComputedStyle(document.documentElement).getPropertyValue('--primary-red')}
            value={state.stack}
            helperText={state.error.stack}
          />

          <p className="sa_devs-multi-needs-text">What stage your product is in?</p>
          <div className="staff-augmentation__devs-services-wrap">
            {options.map(option => (
              <div
                key={'sa_dev_opt-' + option}
                className={`staff-augmentation__devs-service${state.product_stage.includes(option) ? ' selected' : ''}`}
                onClick={() => setStage(option)}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
