import React from 'react';
import {MagentoCompany} from "./magento-development-section";
import {MagentoDeveloperCompany} from "./magento-company";
import {MagentoWhatWeDo} from "./magento-what-we-do";
import {MagentoManage} from "./magento-magane";
import {MagentoSolutions} from "./magento-solutions";
import {MagentoCustomers} from "./magento-customers";
import LetsTalk from "../LetsTalk/LetsTalk";
import AboutCasses from "../reusable/cases/about-casses";

export  const MagentoDevelopmentServices = () =>  {
  return (
    <>
      <MagentoCompany/>
      <MagentoDeveloperCompany/>
      <MagentoWhatWeDo/>
      <MagentoManage/>
      <MagentoSolutions/>
      <MagentoCustomers/>
      <AboutCasses/>
      <LetsTalk />
    </>
  )
}

