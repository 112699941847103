import React from "react";

const Competences = () =>
{
  return (
    <h1 className="about__column">
      Competences
    </h1>
  )
}

export default Competences
