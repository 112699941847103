/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

import {
  SABackendIcon,
  SADatabasesIcon,
  SADevopsIcon,
  SAFrontendIcon,
  SAInfrastructureIcon,
  SAMobileIcon
} from 'images/StaffAugmentation'
import { TextBlocksWithPoints } from 'components/reusable/TextBlocks/with-points'

const technologies = [
  { title: 'Back-End', points: ['NodeJS', 'Ruby on Rails'] },
  { title: 'Front-End', points: ['ReactJS', 'NextJS'] },
  { title: 'Mobile', points: ['React-Native'] },
  { title: 'Databases', points: ['MongoDB', 'PostgresQL', 'MySQL'] },
  { title: 'Infrastructure', points: ['AWS', 'Digital Ocean'] },
  { title: 'Devops', points: ['Docker', 'CI', 'CD'] }
]

const icons = [
  <SABackendIcon />,
  <SAFrontendIcon />,
  <SAMobileIcon />,
  <SADatabasesIcon />,
  <SAInfrastructureIcon />,
  <SADevopsIcon />
]

export const StaffAugmentTechnologies = () => (
  <TextBlocksWithPoints icons={icons} items={technologies} title="Technologies we work with" />
)
