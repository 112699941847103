import * as React from 'react'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import { useState, useEffect, useRef } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'

const ExpandMore = styled( ( props ) =>
{
  const { expand, ...other } = props;
  return <IconButton { ...other } />;
} )( ( { theme, expand } ) => ( {
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create( 'transform', {
    duration: theme.transitions.duration.shortest,
  } ),
} ) );

const Dropdown = ( { title, items } ) =>
{
  const [ expanded, setExpanded ] = React.useState( false )

  const handleExpandClick = () =>
  {
    setExpanded( !expanded );
  }

  const ref = useRef()
  useOnClickOutside( ref, () => setExpanded( false ) )
  function useOnClickOutside ( ref, handler )
  {
    useEffect( () =>
    {
      const listener = ( event ) =>
      {
        if ( !ref.current || ref.current.contains( event.target ) )
        {
          return
        }
        handler( event )
      }
      document.addEventListener( 'mousedown', listener )
      document.addEventListener( 'touchstart', listener )
      return () =>
      {
        document.removeEventListener( 'mousedown', listener )
        document.removeEventListener( 'touchstart', listener )
      }
    }, [ ref, handler ] )
  }

  return (
    <div className={ expanded ? "about__select-2" : "about__select" }>
      <div className='about__select-row-title' onClick={ handleExpandClick }>
        <div className='about__select-title'>
          { title }
        </div>
        <ExpandMore
          expand={ expanded }
          aria-expanded={ expanded }
          aria-label="show more"
        >
          <KeyboardArrowDownIcon />
        </ExpandMore>
      </div>
      { expanded &&
        <div className='about__select-column'>
          { items.map( ( item ) => (
            <div key={ item.label } className='about__select-row-main'>
              <div className='about__select-row'>
                <TrendingFlatIcon />
                <div className='about__select-subtitle'>{ item.label }</div>
              </div>
            </div>
          ) ) }
        </div>
      }
    </div>
  )
}

export default Dropdown
