import React, { Fragment } from 'react'

export const StaffAugmentDevsAvailableStepsProgress = ({ limit, current }) => {
  const steps = Array.from({ length: limit }, (_, i) => i + 1)

  const progressStyle = stp => {
    const res = {}

    if (stp === current) res.transitionDelay = '0.25s'
    if (stp < current) res.borderRadius = 0

    switch (true) {
      case stp === current:
        res.width = '50%'
        break

      case stp < current:
        res.width = '100%'
        break

      default:
        res.width = '0'
    }

    return res
  }

  return (
    <div className="staff-augmentation__devs-steps">
      <div className="staff-augmentation__devs-steps-track" />
      {steps.map(stp => (
        <Fragment key={'devstep_' + stp}>
          <div className={'staff-augmentation__devs-step' + (current >= stp ? ' active' : '')}>
            <div className="staff-augmentation__devs-step-outer">
              <div className="staff-augmentation__devs-step-inner">{stp}</div>
            </div>
          </div>
          {stp !== limit && (
            <div
              className="staff-augmentation__devs-steps-progress-wrap"
              style={{ width: `calc(${100 / (limit - 1)}% - 37px)` }}
            >
              <div className="staff-augmentation__devs-steps-progress" style={progressStyle(stp)} />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  )
}
