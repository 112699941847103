import React from "react"
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'

const CardMibile = ( props ) =>
{
  const onClick = () => window.location.assign( props.link )

  return (
    <div className={ props.className } onClick={ onClick }>
      <div className="about__cases-mobile-row">
        <div className="about__casses-column-text-mobile">
          <div className="about__casses-card-text">
            { props.title }
          </div>
          <div className="about__casses-card-subtext">
            { props.subtitle }
          </div>
        </div>

        <div className="about__casses-column-arrow">
          <ArrowForwardRoundedIcon />
        </div>
      </div>
    </div>
  )
}

export default CardMibile
