import React, {useEffect, useState} from 'react';
import '../../styles/components/magento/magento-customers.scss'
import Subtract from "../../images/Subtract.svg";
import 'swiper/css'
import "swiper/css/pagination"
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import parse from "html-react-parser";
import GradeIcon from "@mui/icons-material/Grade";
import Button from "../reusable/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {getFeedbacks} from "../../features/feedbacksSlice";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';


export const MagentoCustomers = (props) => {

  const dispatch = useDispatch()

  const [centerId, setCenterId] = useState(0)
  const feedbacks = useSelector( state => state.feedbacks.feedbacks )

  useEffect( () => {
    dispatch(getFeedbacks())
  }, [])

  return (
    <div className="magento__column-image">
      <div className="magento__image">
        <img src={ Subtract } className="magento__column-image-background" alt="about" />

        <div className="magento__clients">
          <div className="magento__column-desctop">
            <div className="magento__row-test">
              <div className="magento__text-white">
                Clients thoughts
              </div>
            </div>

            <div className="magento__row-test">
              <div className="magento__text-white">
                about us
              </div>
              <div className="magento__subtext-white">
                Don't just believe the words, see what's our partners say
              </div>
            </div>
          </div>

          <div className="magento__column-mobile">
            <div className="magento__row">
              <div className="magento__text-white">
                Clients thoughts
              </div>
            </div>

            <div className="magento__row">
              <div className="magento__subtext-white">
                Don't just believe the words, see what's our partners say
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="magento__row-clients">
        <div className="magento__row-clients-carusel">
        {feedbacks.length > 0 &&
          <Swiper
            spaceBetween={ 28 }
            slidesPerView={ 3 }
            onSlideChange={ ( swiper ) => setCenterId( swiper.realIndex ) }
            centeredSlides={ true }
            initialSlide={1}
            pagination={ {
              el: '.my-custom-pagination-div',
              clickable: false,
            } }
            speed={1000}
            modules={ [ Pagination ] }
          >
            { feedbacks.map( ( item, index ) => {
              const content = parse( item.content )
              return(
                <SwiperSlide style={ { width: '379px' } } key={index}>
                  <div className={ ( centerId === index ) ? "magento__card-height" : "magento__card" }>
                    <div className={ ( centerId === index ) ? "magento__defaulte-height" : "magento__defaulte" }>
                      <div className="magento__row-ciente">
                        <div className="magento__row-ciente-width">
                          <GradeIcon />
                          <GradeIcon />
                          <GradeIcon />
                          <GradeIcon />
                          <GradeIcon />
                        </div>

                        <div className="magento__row-ciente-width-small">
                          <GradeIcon fontSize="small" />
                          <GradeIcon fontSize="small" />
                          <GradeIcon fontSize="small" />
                          <GradeIcon fontSize="small" />
                          <GradeIcon fontSize="small" />
                        </div>
                      </div>
                      <div className="magento__row-ciente">
                        <img src={ item.avatar_url } className="magento__avatar" alt="avatar" />
                        <div className="magento__column-card">
                          <div className="magento__card-text-main">
                            {item.name}
                          </div>
                          <div className="magento__card-subtext">
                            {item.position}
                          </div>
                        </div>
                      </div>
                      <div className="magento__row-ciente">
                        { (centerId === index) ?
                          <div className="magento__card-info-2">
                            { content }
                          </div>
                          :
                          <>
                            <div className="magento__card-info">
                              { content }
                            </div>
                          </>
                        }
                      </div>
                    </div>
                    <div className="magento__row-secondary">
                      <a href={item.link}>
                        <Button
                          text={ <ArrowForwardOutlinedIcon /> }
                          className="magento__button"
                        />
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              )} ) }
          </Swiper>
        }
        </div>
      </div>
      <div className="my-custom-pagination-div"></div>
    </div>
  );
}

