const commonLevelsTl = ['junior', 'middle', 'senior', 'team lead', "i'm not sure"]
const commonLevels = ['junior', 'middle', 'senior', "i'm not sure"]

export const serviceOptions = [
  { title: 'Software Engineer', steps: 4 },
  { title: 'Project Manager', steps: 5 },
  { title: 'UI/UX Designer', steps: 4 },
  { title: 'Data Analyst', steps: 3 },
  { title: 'QA Specialist', steps: 4 },
  { title: 'DevOps Engineer', steps: 4 },
  { title: 'I want to hire more than one person', steps: 5 }
]

export const serviceSteps = {
  'Software Engineer': {
    steps: 4,
    2: {
      title: 'Select core technologies that the developer has to be good at',
      options: [
        'ruby on rails',
        'node.js',
        'react.js',
        'vue.js',
        'typescript',
        'react native',
        'postgresql',
        'mongodb',
        'other'
      ],
      variant: 'skills',
      multiple: true
    },
    3: {
      title: 'What level of seniority does the engineer have to have?',
      variant: 'level',
      options: commonLevelsTl
    }
  },

  'Project Manager': {
    steps: 5,
    2: {
      title: 'Choose the industry that the PM has to have experience at',
      options: [
        'fitness & wellness',
        'fintech',
        'information technologies',
        'healthcare',
        'e-commerce & retail',
        'entertainment',
        'automotive',
        'e-learning',
        'other'
      ],
      variant: 'skills'
    },
    3: {
      title: 'Select the type of your business so we can suggest the PM',
      options: [
        'startup: seed round',
        'startup: series a. growth',
        'startup: idea stage',
        'business without the app',
        'small or medium business',
        'saas product',
        'enterprise company',
        'other'
      ],
      variant: 'business'
    },
    4: {
      title: 'What level of seniority does the project manager have to have',
      options: commonLevels,
      variant: 'level'
    }
  },

  'UI/UX Designer': {
    steps: 4,
    2: {
      title: 'Select core skills that the designer has to be good at',
      options: [
        'branding',
        'styleguides',
        'ui/ux design',
        'graphic design',
        'wireframing',
        'user journey mapping',
        'html/css prototyping',
        'information architecture',
        'other'
      ],
      variant: 'skills'
    },
    3: {
      title: 'What level of seniority does the designer have to have?',
      options: commonLevelsTl,
      variant: 'level'
    }
  },

  'Data Analyst': {
    steps: 3,
    2: {
      title: 'What level of seniority does the data analyst have to have',
      options: commonLevels,
      variant: 'level'
    }
  },

  'QA Specialist': {
    steps: 4,
    2: {
      title: 'Select core skills that the QA specialist has to be good at',
      options: [
        'usability testing',
        'manual testing (desktop / mobile)',
        'functional testing',
        'regression testing',
        'smoke testing',
        'test documentation',
        'other'
      ],
      multiple: true,
      variant: 'skills'
    },
    3: {
      title: 'What level of seniority does the QA specialist have to have?',
      options: commonLevelsTl,
      variant: 'level'
    }
  },

  'DevOps Engineer': {
    steps: 4,
    2: {
      title: 'Select core technologies that the DevOps has to be good at',
      options: [
        'amazon web services',
        'git',
        'bash',
        'digital ocean',
        'cloudflare',
        'terraform',
        'kubernetes',
        'apache',
        'other'
      ],
      multiple: true,
      variant: 'skills'
    },
    3: {
      title: 'What level of seniority does the DevOps engineer have to have?',
      options: commonLevelsTl,
      variant: 'level'
    }
  }
}

export const serviceStepsMulti = {
  2: {
    title: 'Select the roles of specialists you need',
    options: serviceOptions.filter((_, i, a) => i !== a.length - 1).map(so => so.title)
  },
  3: {
    title: 'What level of seniority should specialists have?',
    options: {
      'Software Engineer': commonLevelsTl,
      'Project Manager': commonLevels,
      'UI/UX Designer': commonLevelsTl,
      'Data Analyst': commonLevels,
      'QA Specialist': commonLevelsTl,
      'DevOps Engineer': commonLevelsTl
    }
  },
  4: {
    title: 'Add project details so we can better understand your needs',
    options: ['idea', 'mvp', 'market-ready product', 'Startup(Seed, Series A, B stages)', 'other']
  }
}
