import React from 'react';
import HomeLogo2 from "../../../images/Home/HomeLogo2.svg";
import HomeLogo7 from "../../../images/Home/HomeLogo7.svg";
import HomeLogo6 from "../../../images/Home/HomeLogo6.svg";
import HomeLogo5 from "../../../images/Home/HomeLogo5.svg";
import HomeLogo3 from "../../../images/Home/HomeLogo3.svg";
import HomeLogo1 from "../../../images/Home/HomeLogo1.svg";

function FireAnimationMobile() {
  return (
    <div className="about__row-image-second-mobile">
      <div className="about__div-image-1-mobile">
        <img src={ HomeLogo2 } alt="about" className="about__main-image-1-mobile" />
      </div>
      <div className="about__div-image-2-mobile">
        <img src={ HomeLogo7 } alt="about" className="about__main-image-1-mobile" />
      </div>
      <div className="about__div-image-3-mobile">
        <img src={ HomeLogo6 } alt="about" className="about__main-image-1-mobile" />
      </div>
      <div className="about__div-image-4-mobile">
        <img src={ HomeLogo5 } alt="about" className="about__main-image-1-mobile" />
      </div>
      <div className="about__div-image-5-mobile">
        <img src={ HomeLogo3 } alt="about" className="about__main-image-1-mobile" />
      </div>
      <div className="about__div-image-6-mobile">
        <img src={ HomeLogo2 } alt="about" className="about__main-image-1-mobile" />
      </div>
      <div className="about__div-image-7-mobile">
        <img src={ HomeLogo7 } alt="about" className="about__main-image-1-mobile" />
      </div>
      <div className="about__div-image-8-mobile">
        <img src={ HomeLogo1 } alt="about" className="about__main-image-1-mobile" />
      </div>
    </div>
  );
}

export default FireAnimationMobile;