import React from 'react'

import { TextBlocksWithPoints } from 'components/reusable/TextBlocks/with-points'
import {
  ETEEcommerceIcon,
  ETEEntertainment,
  ETEFintechIcon,
  ETEFitnessIcon,
  ETEHealthcareIcon,
  ETEITIcon
} from 'images/EndToEnd'

const industries = [
  {
    title: 'ECOMMERCE & RETAIL',
    points: [
      'eCommerce Platforms',
      'Trading Portals',
      'B2C Marketplaces',
      'Retail Inventory Software',
      'Online Ordering Systems',
      'CRM Solutions for eCommerce'
    ]
  },
  {
    title: 'FITNESS & WELLNESS',
    points: [
      'Gym Management Systems',
      'Personal Training Software',
      'Wellness Tracking Software',
      'Mental Health Management',
      'Platforms',
      'Inventory Management Solutions'
    ]
  },
  {
    title: 'HEALTHCARE',
    points: [
      'EHR & ERM Systems',
      'Patient DB Management',
      'Patient Engagement Apps',
      'mHealth Solutions',
      'eRx Websites',
      'Medical Portals'
    ]
  },
  {
    title: 'INFORMATION TECHNOLOGY',
    points: [
      'SaaS Platforms',
      'Computers & Electronics',
      'Manufacturing Solutions',
      'Marketplaces for Data Centers',
      'Platform for No-code App Building'
    ]
  },
  {
    title: 'ENTERTAINMENT',
    points: [
      'Networking Apps',
      'Hiring Platform for Fashion Industry',
      'Streaming Media Platform',
      'P2P Social Media Platform',
      'Solutions for Fashion Industry'
    ]
  },
  {
    title: 'FINTECH',
    points: [
      'Exchange & Trading Platforms',
      'P2P Lending Software',
      'Fraud Prevention Solutions',
      'MySQL',
      'Secure Banking Solutions',
      'Crowdfunding Platforms'
    ]
  }
]
const icons = [
  <ETEEcommerceIcon />,
  <ETEFitnessIcon />,
  <ETEHealthcareIcon />,
  <ETEITIcon />,
  <ETEEntertainment />,
  <ETEFintechIcon />
]

export const EndToEndIndustries = () => (
  <TextBlocksWithPoints items={industries} icons={icons} title="Industries we're experienced with" />
)
