import React from "react";

export const NextArrow = ({ onClick, active }) => {
  const color = active ?  "#989494" : "#0c0303"
  return (
    <svg onClick={onClick} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M21.5188 18.9999L11.6764 18.9999L11.6764 16.9999L21.5187 16.9999L16.6262 12.1074L18.0404 10.6932L24.6401 17.2929L25.3472 18L24.6401 18.7071L18.0404 25.3067L16.6262 23.8925L21.5188 18.9999Z"
            fill={color}/>
      <rect x="1" y="1" width="34" height="34" rx="17" stroke={color} strokeWidth="2"/>
    </svg>
  );
};


export const PrevArrow = ({ onClick, active }) => {
  const color = active ? "#989494"  : "#0c0303"

  return (
    <svg onClick={onClick}   width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.4812 19L19.3738 23.8925L17.9595 25.3067L11.3599 18.7071L10.6528 18L11.3599 17.2929L17.9595 10.6932L19.3738 12.1074L14.4811 17H24.3236V19H14.4812Z"
            fill={color}/>
      <rect x="1" y="1" width="34" height="34" rx="17" stroke={color} strokeWidth="2"/>
    </svg>
  );
};


export const NextArrow1 = ({ onClick, active }) => {
  const color = active ?  "#7c7979" : "#110f0f"
  return (
    <svg onClick={onClick} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M21.5188 18.9999L11.6764 18.9999L11.6764 16.9999L21.5187 16.9999L16.6262 12.1074L18.0404 10.6932L24.6401 17.2929L25.3472 18L24.6401 18.7071L18.0404 25.3067L16.6262 23.8925L21.5188 18.9999Z"
            fill={color}/>
      <rect x="1" y="1" width="34" height="34" rx="17" stroke={color} strokeWidth="2"/>
    </svg>
  );
};


export const PrevArrow1 = ({ onClick, active }) => {
  const color = active ?  "#7c7979" : "#110f0f"

  return (
    <svg onClick={onClick}   width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.4812 19L19.3738 23.8925L17.9595 25.3067L11.3599 18.7071L10.6528 18L11.3599 17.2929L17.9595 10.6932L19.3738 12.1074L14.4811 17H24.3236V19H14.4812Z"
            fill={color}/>
      <rect x="1" y="1" width="34" height="34" rx="17" stroke={color} strokeWidth="2"/>
    </svg>
  );
};


export const NextArrow2 = ({ onClick, active }) => {
  const color = active ?  "#dad9d9" : "#737171"
  return (
    <svg onClick={onClick} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M21.5188 18.9999L11.6764 18.9999L11.6764 16.9999L21.5187 16.9999L16.6262 12.1074L18.0404 10.6932L24.6401 17.2929L25.3472 18L24.6401 18.7071L18.0404 25.3067L16.6262 23.8925L21.5188 18.9999Z"
            fill={color}/>
      <rect x="1" y="1" width="34" height="34" rx="17" stroke={color} strokeWidth="2"/>
    </svg>
  );
};


export const PrevArrow2 = ({ onClick, active }) => {
  const color = active ?  "#dad9d9" : "#737171"

  return (
    <svg onClick={onClick}   width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.4812 19L19.3738 23.8925L17.9595 25.3067L11.3599 18.7071L10.6528 18L11.3599 17.2929L17.9595 10.6932L19.3738 12.1074L14.4811 17H24.3236V19H14.4812Z"
            fill={color}/>
      <rect x="1" y="1" width="34" height="34" rx="17" stroke={color} strokeWidth="2"/>
    </svg>
  );
};