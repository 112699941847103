import React, { useState } from 'react'

import { serviceOptions, serviceSteps } from './components/steps-data'
import { StaffAugmentDevsAvailableStepsProgress } from './components/steps-progress'
import { StaffAugmentDevsAvailableOptions } from './components/options'

import { ReactComponent as Arrow } from 'images/Cases/arrow2.svg'
import { StaffAugmentDevsAvailableForm } from './components/steps-form'
import { StaffAugmentDevsAvailableOptionsMulti } from './components/options-multiple'

const initialDev = {
  service: 0,
  level: '',
  skills: []
}

const initialState = {
  service: 0,
  stack: '',
  product_stage: '',
  developers: [{ ...initialDev }],
  error: {
    stack: null,
    services: null
  }
}

export const StaffAugmentDevsAvailable = () => {
  const [step, setStep] = useState(1)
  const [state, setState] = useState({ ...initialState })

  const stepLimit = serviceOptions[state.service].steps || 4
  const serviceTitle = serviceOptions[state.service].title
  const serviceOptionsVariant = serviceSteps[serviceTitle]?.[step]?.variant
  const isMulti = state.service === serviceOptions.length - 1

  const setService = i => {
    if (i === serviceOptions.length - 1) {
      setState({ ...initialState, service: i, developers: [] })
    } else {
      setState({ ...initialState, developers: [{ ...initialDev, service: serviceOptions[i].title }], service: i })
    }

    setStep(1)
  }

  const stepUp = () => {
    if (isMulti) {
      if (step === 2 && !state.developers.length) {
        setState(ps => ({
          ...ps,
          error: { ...ps.error, services: 'Please pick at least one service' }
        }))
        return
      }
      if (step === 4 && state.stack.length < 10) {
        setState(ps => ({
          ...ps,
          error: {
            ...ps.error,
            stack: 'The message must contain at least 10 characters'
          }
        }))

        return
      }
    }

    if (step < stepLimit) setStep(ps => ps + 1)
  }

  const stepDown = () => {
    if (step > 1) {
      setStep(ps => ps - 1)
      setState(ps => ({ ...ps, error: { ...initialState.error } }))
    }
  }

  const setOption = variant => value => {
    if (serviceSteps[serviceTitle][step].multiple) {
      const dev = state.developers[0]
      const opts = dev[variant]

      if (opts.includes(value)) opts.splice(opts.indexOf(value), 1)
      else opts.push(value)

      dev[variant] = opts

      setState(ps => ({ ...ps, developers: [dev], error: { ...initialState.error } }))
    } else {
      const dev = state.developers[0]
      dev[variant] = value

      setState(ps => ({ ...ps, developers: [dev], error: { ...initialState.error } }))
    }
  }

  const renderContent = () => {
    switch (true) {
      case step === 1:
        return (
          <div>
            <div className="staff-augmentation__title">
              <p className="staff-augmentation__title-text">What kind of services do you need?</p>
            </div>
            <div className="staff-augmentation__devs-services-wrap">
              {serviceOptions.map(({ title: so }, i) => (
                <div
                  key={'sa_dev_srvc-' + so}
                  className={`staff-augmentation__devs-service${state.service === i ? ' selected' : ''}`}
                  onClick={() => setService(i)}
                >
                  {so}
                </div>
              ))}
            </div>
          </div>
        )

      case stepLimit === step:
        return <StaffAugmentDevsAvailableForm devsData={state} />

      default:
        return isMulti ? (
          <StaffAugmentDevsAvailableOptionsMulti step={step} state={state} setState={setState} setOption={setOption} />
        ) : (
          <StaffAugmentDevsAvailableOptions
            serviceStep={serviceSteps[serviceTitle][step]}
            selected={state.developers[0][serviceOptionsVariant]}
            handleClick={setOption(serviceOptionsVariant)}
          />
        )
    }
  }

  return (
    <section className="staff-augmentation__section sa_devs-available">
      <div className="staff-augmentation__devs-block">
        <div className="staff-augmentation__title">
          <p className="staff-augmentation__title-text">See developers available</p>
        </div>
        <p className="staff-augmentation__devs-subtitle">
          Please, select the options that best reflect what kind of talents you want to hire. If there are no suitable
          options, you can fill in the contact us form so that we assist you.
        </p>

        <StaffAugmentDevsAvailableStepsProgress current={step} limit={stepLimit} />
      </div>

      <div className="staff-augmentation__devs-separator" />

      <div className="staff-augmentation__devs-block">
        {renderContent()}

        {state.error.services && <p className="staff-augmentation__devs-error">{state.error.services}</p>}

        <div className="staff-augmentation__devs-buttons">
          <button className="staff-augmentation__devs-button" onClick={stepDown} disabled={step === 1}>
            <div className="staff-augmentation__devs-button-icon">
              <Arrow />
            </div>
            back
          </button>
          <button
            className="staff-augmentation__devs-button"
            onClick={stepUp}
            disabled={step === stepLimit || state.error.stack || state.error.services}
          >
            next
            <div className="staff-augmentation__devs-button-icon">
              <Arrow />
            </div>
          </button>
        </div>
      </div>
    </section>
  )
}
