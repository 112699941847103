import { useCallback, useState } from 'react'
import { findIndex, isEmpty } from 'lodash'
import api from 'helpers/api'

const initialState = {
  name: '',
  email: '',
  message: '',
  files: [],
  vacancy: [],
  developersData: {},
  error: {
    name: false,
    email: false,
    message: false
  }
}

const validateEmail = email => {
  const re =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

/**
 * @param onSubmit - function to send data to backend
 * @returns formData, handleChange, handleFileRemoval, handleSubmit
 */

export const useContactForm = onSubmit => {
  const [state, setState] = useState({ ...initialState })

  const handleFileRemoval = fileName => {
    setState(ps => {
      const files = [...ps.files]
      const index = findIndex(files, { name: fileName })
      files.splice(index, 1)

      return {
        ...ps,
        files
      }
    })
  }

  const handleChange = useCallback(
    field =>
      ({ target: { value, files } }) => {
        setState(ps => ({
          ...ps,
          [field]: field === 'files' ? [...ps.files, ...files] : value,
          error: {
            ...ps.error,
            [field]: false
          }
        }))
      },
    []
  )

  const validate = () => {
    let error = {
      name: false,
      email: false,
      message: false
    }

    if (!state.name || state.name.length < 2) {
      error = { ...error, name: true }
    }
    if (!state.email || !validateEmail(state.email)) {
      error = { ...error, email: true }
    }
    if (!state.message || state.message.length < 10) {
      error = { ...error, message: true }
    }

    setState(ps => ({
      ...ps,
      error
    }))

    return error
  }

  const buildForm = () => {
    const form = new FormData()

    const { files } = state
    const searchParams = new URLSearchParams(window.location.search)
    const utmData = {
      source: searchParams.get('utm_source'),
      campaign: searchParams.get('utm_campaign')
    }

    form.append('name', state.name)
    form.append('email', state.email)
    form.append('message', state.message)

    if (state.vacancy?.length) form.append('position', state.vacancy)
    if (files) files.forEach(file => form.append('files[]', file))
    if (utmData.source) form.append('utm_source', utmData.source)
    if (utmData.campaign) form.append('utm_campaign', utmData.campaign)

    return form
  }

  const handleSubmit = async () => {
    const error = validate()

    if (!error.name && !error.message && !error.email) {
      const form = buildForm()

      onSubmit(form)?.then(() => setState({ ...initialState }))
    }
  }

  return {
    formData: state,
    handleChange,
    handleFileRemoval,
    handleSubmit
  }
}
