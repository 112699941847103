import React from 'react'

import Logo from '../../../images/404.svg'
import Button from '../Button/Button'

import { ReactComponent as Arrow } from '../../../images/Cases/arrow2.svg'

const Error = ({ soon }) => {
  return (
    <div className="error__wrapper">
      <div className="error__column-not-found">
        {soon ? (
          <h1>Coming soon!</h1>
        ) : (
          <>
            <img src={Logo} alt="logo" className="error__logo" />
            <div className="error__column-text">
              <div className="error__text-not-found">Page Not Found</div>
              <div className="error__subtext-not-found">
                Oops, you got lost, but we'll lead you out of this non-existent
                page. There's a better place you might go:
              </div>
            </div>
          </>
        )}

        <Button
          className="error__button"
          classNameText="error__button-text-row"
          onClick={() => window.location.assign('/')}
          text={
            <>
              <div className="error__button-text">Go to main page </div>
              <Arrow />
            </>
          }
        />
      </div>
    </div>
  )

}
export default Error
