import React, {useState} from "react"
import GradeIcon from '@mui/icons-material/Grade'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Pagination} from "swiper";
import 'swiper/css'
import "swiper/css/pagination"
import {HorizontalTicker} from "react-infinite-ticker"
import parse from "html-react-parser"
import {isMacOs} from "react-device-detect"

import Subtract from '../../images/Subtract.svg'
import MiniLogo from '../../images/MiniLogo.svg'
import MiniLogo1 from '../../images/MiniLogo1.svg'
import MiniLogo2 from '../../images/MiniLogo2.svg'
import MiniLogo3 from '../../images/MiniLogo3.svg'
import MiniLogo4 from '../../images/MiniLogo4.svg'
import Button from '../reusable/Button/Button'
import Competences from './Competences/Competences1'
import Background from '../../images/Home/Background.svg'
import Background2 from '../../images/Home/Background2.jpg'
import ClutchSmall from '../../images/Home/Numbers/Clutch.svg'
import ClutchRed from '../../images/Home/Clutch-red.svg'
import G2Small from '../../images/Home/G2Small.svg'
import GoodFirmsSmall from '../../images/Home/GoodFirmsSmall.svg'
import LetsTalk from "../LetsTalk/LetsTalk"
import AboutCasses from "../reusable/cases/about-casses";
import FireAnimationDesktop from '../reusable/FireAnimation/fire_animation_desktop'
import FireAnimationMobile from '../reusable/FireAnimation/fire_animation_mobile'

const Home = (props) => {
  const scrollToBottom = () => {
    window.scrollTo({
      top: 750,
      behavior: 'smooth',
    })
  }

  const [centerId, setCenterId] = useState(0)
  const home_page_cards = props.home_page_cards
  const feedbacks = props.feedbacks
  let cardsId = 0

  const setCardId = () => {
    if (cardsId === 4) {
      cardsId -= 3
    } else {
      cardsId += 1
    }
    return cardsId
  }

  return (<div className="about__wrapper">
    <div className="about__column-first">
      <div className="about__row-main">
        <div className="about__column-main-first">
          <div className="about__row-main-text">
            <div className="about__text-main">
              Remote
            </div>
          </div>
          <div className="about__row-main-text">
            <div className="about__text-main">
              software
            </div>
          </div>
          <div className="about__row-main-text">
            <div className="about__text-main">
              development
            </div>
          </div>
          <div className="about__row-main-text-2">
            <div className="about__text-main">
              teams
            </div>
            <div className="about__text-main-secondary" style={isMacOs ? {marginTop: '1%'} : undefined}>
              We deliver carefully designed software through engineering, UI/UX, QA, and DevOps expertise
            </div>
          </div>
        </div>

        <FireAnimationDesktop/>
      </div>

      <div className="about__column-main-second-mobile">
        <div className="about__row-main-first-mobile">
          <div className="about__column-to-bottom-second-mobile">
            <div className="about__to-bottom-main-text">
              End-to-end development
            </div>
            <div className="about__to-bottom-secondary-text">
              We build custom software from scratch, modernize, scale, and optimize the existing functionality of any
              complexity. Timely, with a focus on business objectives.
            </div>
          </div>
          <div className="about__column-to-bottom-second-mobile">
            <div className="about__to-bottom-main-text">
              Staff augmentation
            </div>
            <div className="about__to-bottom-secondary-text">
              Extend your in-house team or set up a fully remote team with skilled experts who can meet your business
              goals. Suitable professionals in just weeks, not months.
            </div>
          </div>
        </div>
        <div className="about__row-main-second-mobile">
          <div className="about__g2-scrol">
            <img src={ClutchSmall} alt="about" className="about__g2-small"/>
            <img src={GoodFirmsSmall} alt="about" className="about__g2-small"/>
            <img src={G2Small} alt="about" className="about__g2-small"/>
          </div>
        </div>
      </div>

      <div className="about__row-image-first-mobile">
        <FireAnimationMobile/>
      </div>

      <div className="about__row-image-first-mobile">
        <div className="about__div-image-9-mobile">
          <img src={Background2} alt="about" className="about__div-image-main-mobile-2"/>
        </div>
      </div>

      <div className="about__div-image-main-mobile"></div>

      <img src={Background} alt="about" className="about__div-image-main"/>

      <div className="about__div-image-main-2"></div>

      <div className="about__row-info">
        <div className="about__to-bottom">
          <Button
            className='about__button-to-bottom'
            onClick={scrollToBottom}
            text={<ArrowDownwardRoundedIcon/>}
            classNameText='about__button-to-bottom-text'/>
        </div>
        <div className="about__to-bottom-second">
          <div className="about__column-to-bottom-second">
            <div className="about__to-bottom-main-text">
              End-to-end development
            </div>
            <div className="about__to-bottom-secondary-text">
              We build custom software from scratch, modernize, scale, and optimize the existing functionality of any
              complexity. Timely, with a focus on business objectives.
            </div>
          </div>
        </div>
        <div className="about__to-bottom-second">
          <div className="about__column-to-bottom-second">
            <div className="about__to-bottom-main-text">
              Staff augmentation
            </div>
            <div className="about__to-bottom-secondary-text">
              Extend your in-house team or set up a fully remote team with skilled experts who can meet your business
              goals. Suitable professionals in just weeks, not months.
            </div>
          </div>
        </div>
        <div className="about__to-bottom-third">
          <div className="about__row-g2">
            <img src={ClutchSmall} alt="about" className="about__g2-main-1"/>
          </div>

          <div className="about__row-g2-2">
            <img src={ClutchRed} alt="about" className="about__g2-main"/>
            <img src={GoodFirmsSmall} alt="about" className="about__g2-main"/>
            <img src={G2Small} alt="about" className="about__g2-main"/>
          </div>
        </div>
      </div>
    </div>

    <div className="about__column">
      <div className="about__row">
        <div className="about__text">
          Technical solutions
        </div>
      </div>

      <div className="about__row">
        <div className="about__subtext">
          From conception to full-fledged product
        </div>
      </div>

      <div className="about__row-2">
        <div className="about__column-with-line">
          <div className="about__info-main">
            <img src={MiniLogo} alt="logo" className="about__info-main-img"/>&nbsp;10+ years
          </div>
          <div className="about__info-secondary">
            Years on the market
          </div>
        </div>

        <div className="about__vl"/>

        <div className="about__column-with-line-2">
          <div className="about__info-main">
            <img src={MiniLogo1} alt="logo" className="about__info-main-img"/>&nbsp; 96,8%
          </div>
          <div className="about__info-secondary">
            NPS rate
          </div>
        </div>

        <div className="about__vl"/>

        <div className="about__column-with-line">
          <div className="about__info-main">
            <img src={MiniLogo2} alt="logo" className="about__info-main-img"/>&nbsp; 72%
          </div>
          <div className="about__info-secondary">
            Middle and senior developers
          </div>
        </div>

        <div className="about__vl-1"/>

        <div className="about__column-with-line-2">
          <div className="about__info-main">
            <img src={MiniLogo3} alt="logo" className="about__info-main-img"/>&nbsp; 50+
          </div>
          <div className="about__info-secondary">
            Completed projects
          </div>
        </div>

        <div className="about__vl"/>

        <div className="about__column-with-line">
          <div className="about__info-main">
            <img src={MiniLogo4} alt="logo" className="about__info-main-img"/>&nbsp; 55+
          </div>
          <div className="about__info-secondary">
            Professionals
          </div>
        </div>
      </div>
    </div>

    <div className="about__colomn-blue">
      <div className="about__images-show">
        <HorizontalTicker duration={50000}>
          <div className="about__images">
            {home_page_cards.map((item, i) => {
              const id = setCardId()
              return (
                <React.Fragment key={i}>
                  <img src={item.image_url} className="about__card-left" alt="about"/>
                  {id === 1 &&
                    <div className="about__card-right">
                      <div className="about__card-text">
                        {item.content}
                      </div>
                    </div>
                  }

                  {id === 2 &&
                    <div className="about__card-red">
                      <div className="about__card-text">
                        {item.content}
                      </div>
                    </div>
                  }

                  {id === 3 &&
                    <div className="about__card-grey">
                      <div className="about__card-text" style={{color: 'rgba(17, 21, 23, 0.6)'}}>
                        {item.content}
                      </div>
                    </div>
                  }

                  {id === 4 &&
                    <div className="about__card-white">
                      <div className="about__card-text" style={{color: 'rgba(17, 21, 23, 0.6)'}}>
                        {item.content}
                      </div>
                    </div>
                  }
                </React.Fragment>
              )
            })}
          </div>
        </HorizontalTicker>
      </div>
    </div>

    <div className="about__column-image">
      <div className="about__image">
        <img src={Subtract} className="about__column-image-background" alt="about"/>

        <div className="about__clients">
          <div className="about__column-desctop">
            <div className="about__row-test">
              <div className="about__text-white">
                Clients thoughts
              </div>
            </div>

            <div className="about__row-test">
              <div className="about__text-white">
                about us
              </div>
              <div className="about__subtext-white">
                Don't just believe the words, see what's our partners say
              </div>
            </div>
          </div>

          <div className="about__column-mobile">
            <div className="about__row">
              <div className="about__text-white">
                Clients thoughts
              </div>
            </div>

            <div className="about__row">
              <div className="about__subtext-white">
                Don't just believe the words, see what's our partners say
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about__row-clients">
        <div className="about__row-clients-carusel">
          {feedbacks.length > 0 &&
            <Swiper
              spaceBetween={28}
              slidesPerView={3}
              onSlideChange={(swiper) => setCenterId(swiper.realIndex)}
              centeredSlides={true}
              initialSlide={1}
              pagination={{
                el: '.my-custom-pagination-div',
                clickable: false,
              }}
              speed={1000}
              modules={[Pagination]}
            >
              {feedbacks.map((item, index) => {
                const content = parse(item.content)
                return (
                  <SwiperSlide style={{width: '379px'}} key={index}>
                    <div className={(centerId === index) ? "about__card-height" : "about__card"}>
                      <div className={(centerId === index) ? "about__defaulte-height" : "about__defaulte"}>
                        <div className="about__row-ciente">
                          <div className="about__row-ciente-width">
                            <GradeIcon/>
                            <GradeIcon/>
                            <GradeIcon/>
                            <GradeIcon/>
                            <GradeIcon/>
                          </div>

                          <div className="about__row-ciente-width-small">
                            <GradeIcon fontSize="small"/>
                            <GradeIcon fontSize="small"/>
                            <GradeIcon fontSize="small"/>
                            <GradeIcon fontSize="small"/>
                            <GradeIcon fontSize="small"/>
                          </div>
                        </div>
                        <div className="about__row-ciente">
                          <img src={item.avatar_url} className="about__avatar" alt="avatar"/>
                          <div className="about__column-card">
                            <div className="about__card-text-main">
                              {item.name}
                            </div>
                            <div className="about__card-subtext">
                              {item.position}
                            </div>
                          </div>
                        </div>
                        <div className="about__row-ciente">
                          {(centerId === index) ?
                            <div className="about__card-info-2">
                              {content}
                            </div>
                            :
                            <>
                              <div className="about__card-info">
                                {content}
                              </div>
                            </>
                          }
                        </div>
                      </div>
                      <div className="about__row-secondary">
                        <a href={item.link}>
                          <Button
                            text={<ArrowOutwardIcon/>}
                            className="about__button"
                          />
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          }
        </div>
      </div>

      <div className="my-custom-pagination-div"></div>
    </div>

    <div className="about__column-services">
      <div className="about__row">
        <div className="about__text">
          Our Services
        </div>
      </div>

      <div className="about__row">
        <div className="about__subtext">
          Discover the range of services we leverage to achieve business
        </div>
      </div>

      <div className="about__row">
        <div className="about__subtext">
          outcomes our partners aim at
        </div>
      </div>

      <Competences/>
    </div>
    <AboutCasses/>
    <LetsTalk/>
  </div>)
}

export default Home
