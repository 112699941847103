import React from 'react'

import { ReactComponent as BulletArrow } from 'images/careerBullet.svg'

const benefits1 = [
  'Remote engineers that share your corporate standards & values',
  'Processes are adjusted to your development frameworks, timezone & tools',
  'Predictable growth of your product and transparent budget planning'
]

const benefits2 = [
  'Flexible team scaling up and down considering ongoing project needs',
  'Strong Middle and Senior experts only based on your requirements',
  'More cost-efficient choice than hiring un-house team with the same skillset.'
]

export const StaffAugmentBenefits = () => (
  <section className="staff-augmentation__section">
    <div className="staff-augmentation__title sa_benefits">
      <p className="staff-augmentation__title-text">Benefits of staff</p>
      <p className="staff-augmentation__title-text">augmentation with us</p>
    </div>

    <div className="staff-augmentation__benefits-wrap">
      <div className="staff-augmentation__benefits-list">
        {benefits1.map((bnft, i) => (
          <div className="staff-augmentation__benefits-item" key={'bnft1_' + i}>
            <div>
              <BulletArrow className="staff-augmentation__benefits-bullet" />
            </div>
            {bnft}
          </div>
        ))}
      </div>
      <div className="staff-augmentation__benefits-list">
        {benefits2.map((bnft, i) => (
          <div className="staff-augmentation__benefits-item" key={'bnft2_' + i}>
            <div>
              <BulletArrow className="staff-augmentation__benefits-bullet" />
            </div>
            {bnft}
          </div>
        ))}
      </div>
    </div>
  </section>
)
