import React from 'react'

import { EndToEndTextBlocks } from './text-blocks'
import { EndToEndTopSection } from './top-section'
import { EndToEndStages } from './stages'
import { EndToEndWWYG } from './what-you-will-get'
import { EndToEndIndustries } from './industries'

import CardMibile from 'components/Cases/Card/CardMobile'
import Card from 'components/Cases/Card/Card'
import LetsTalk from 'components/LetsTalk/LetsTalk'

import LevereX from '../../images/Home/LevereX.jpg'
import onpodio from '../../images/Home/onpodio.jpg'
import Swipecast from '../../images/Home/Swipecast.jpg'

export const EndToEndSoftwareDevelopment = () => {
  return (
    <>
      <EndToEndTopSection />
      <EndToEndTextBlocks />
      <EndToEndStages />
      <EndToEndWWYG />
      <EndToEndIndustries />

      <div className="about__casses-container ete">
        <div className="about__casses-row">
          <div className="about__casses-column-second">
            <div className="about__casses-title">Our Cases</div>
            <div className="about__casses-subtitle">
              Here's how we created web and mobile apps for SMBs, startups, and enterprise clients
            </div>

            <div className="about__casses-cards-column">
              <Card
                title="OnPodio"
                subtitle="Fitness Marketplace"
                className="home__casses-card-1"
                link="https://www.onpodio.com/"
              />
              <Card
                title="LevereX"
                subtitle="Trading platform"
                className="home__casses-card-2"
                link="https://www.leverex.io/"
              />
              <Card
                title="Swipecast"
                subtitle="Booking/Hiring platform for the fashion industry"
                className="home__casses-card-3"
                link="https://www.swipecast.com/"
              />
              <Card title="All Projects" className="home__casses-card-gray" link="/portfolio" />

              <div className="casses-block-1">
                <img src={onpodio} alt="progect" className="casses-image-1" />
              </div>

              <div className="casses-block-2">
                <img src={LevereX} alt="progect" className="casses-image-2" />
              </div>

              <div className="casses-block-3">
                <img src={Swipecast} alt="progect" className="casses-image-3" />
              </div>
            </div>
          </div>

          <div className="about__casses-tablet-row">
            <div className="about__casses-tablet-column">
              <div className="about__casses-tablet-row-title">Our Cases</div>
              <div className="about__casses-tablet-row-subtitle">
                Here's how we created web and mobile apps for SMBs, startups, and enterprise clients
              </div>
            </div>
          </div>
          <div className="about__casses-tablet-column-second">
            <CardMibile
              title="OnPodio"
              subtitle="Fitness Marketplace"
              className="about__casses-tablet-card"
              link="https://www.onpodio.com/"
            />
            <CardMibile
              title="LevereX"
              subtitle="Trading platform"
              className="about__casses-tablet-card"
              link="https://www.leverex.io/"
            />
            <CardMibile
              title="Swipecast"
              subtitle="Booking/Hiring platform for the fashion industry"
              className="about__casses-tablet-card"
              link="https://www.swipecast.com/"
            />
            <CardMibile title="All Projects" className="about__casses-tablet-card-grey" link="/cases" />
          </div>
          <div className="about__casses-tablet-row-third">
            <img src={Swipecast} alt="progect" className="about__casses-tablet-image-1" />
            <img src={LevereX} alt="progect" className="about__casses-tablet-image-2" />
            <img src={onpodio} alt="progect" className="about__casses-tablet-image-3" />
          </div>
        </div>
      </div>

      <LetsTalk
        className="sa_hire-specialists"
        heading1="GET THE ESTIMATES FOR "
        heading2="YOUR SOFTWARE SOLUTION"
        subtext="Want to know whether we can help with your challenges? Well, most likely, we can.
                 Feel free to provide us with the details of your product, such as the tech stack,
                 the current state of the product, and your goals & expectations. We'll get back
                 to you in a short while.
        "
      />
    </>
  )
}
