import * as React from 'react'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import { useState, useEffect, useRef } from 'react'
import { includes } from 'lodash'

import s from '../Dropdown/Dropdown.module.css'
import Plus from '../../../images/Cases/Plus.svg'

const ExpandMore = styled( ( props ) =>
{
  const { expand, ...other } = props;
  return <IconButton { ...other } />;
} )( ( { theme, expand } ) => ( {
  transform: !expand ? 'rotate(0deg)' : 'rotate(45deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create( 'transform', {
    duration: theme.transitions.duration.shortest,
  } ),
} ) );

const Select = ( { title, items, industry, setIndustry }) =>
{
  const [ expanded, setExpanded ] = React.useState( false )

  const handleExpandClick = () =>
  {
    setExpanded( !expanded );
  }

  const ref = useRef()
  useOnClickOutside( ref, () => setExpanded( false ) )
  function useOnClickOutside ( ref, handler )
  {
    useEffect( () =>
    {
      const listener = ( event ) =>
      {
        if ( !ref.current || ref.current.contains( event.target ) )
        {
          return
        }
        handler( event )
      }
      document.addEventListener( 'mousedown', listener )
      document.addEventListener( 'touchstart', listener )
      return () =>
      {
        document.removeEventListener( 'mousedown', listener )
        document.removeEventListener( 'touchstart', listener )
      }
    }, [ ref, handler ] )
  }

  const onClick = ( element, industry ) => {
    if (includes( industry, element )) {
      setIndustry( industry.filter( item => item !== element ) )
    }
    else {
      setIndustry( arr => [ ...arr, element ] )
    }
  }

  return (
    <label ref={ ref } for={title} className={ expanded ? "cases__select-2" : "cases__select" }>
      <div className='cases__select-row-title'>
        <div className='cases__select-title'>
          {title}
        </div>
        <ExpandMore
          expand={ expanded }
          onClick={ handleExpandClick }
          aria-expanded={ expanded }
          aria-label="show more"
          id={ title }
        >
          <img src={ Plus } alt="plus" />
        </ExpandMore>
      </div>
      { expanded &&
        <div className='cases__select-column'>
          { items.map ((item) => (
            <label for={ item.label } className='cases__select-row-main'>
              <div className='cases__select-row'>
                <div className='cases__select-subtitle'>
                  {item.label}
                </div>

                <div className={ s.checkBox }>
                  <label className={ s.checkBoxContainer }>
                    <input
                      name="cb"
                      id={ item.label }
                      type="checkbox"
                      checked={ includes(industry, item.value) }
                      onChange={ () => onClick( item.value, industry )}
                    />
                    <span className={ s.checkmark }></span>
                  </label>
                </div>
              </div>
            </label>
          ))}
        </div>
      }
    </label>
  )
}

export default Select
