import React, { useState } from 'react'

import { ReactComponent as BulletArrow } from 'images/careerBullet.svg'
import { ReactComponent as ChevronRight } from 'images/chevron-right.svg'
import { ReactComponent as Arrow } from 'images/Cases/arrow2.svg'

const stages = [
  {
    heading: 'INITIATION',
    text: [
      'Gathering requirements',
      'Product audit',
      'Rought project estimate',
      'Tech stack defining',
      'Solutions mapping',
      'Business proposal'
    ]
  },
  {
    heading: 'PLANNING',
    text: ['Discovery workshops', 'Product roadmap', 'System design', 'Project management', 'Information architecture']
  },
  {
    heading: 'DESIGN',
    text: [
      'Brand identity',
      'Styleguide',
      'Wireframes',
      'UX research & strategy',
      'Graphic design',
      'HTML/CSS prototypes',
      'Data & software architecture'
    ]
  },
  {
    heading: 'DEVELOPMENT & TESTING',
    text: [
      'Custom web applications',
      'APIs setups',
      "Product's business logic",
      'Tests automation',
      'Security & usability testing',
      'Continuous integration'
    ]
  },
  {
    heading: 'DEPLOYMENT',
    text: [
      'Continuous delivery',
      'Server, cloud, microservices solutions',
      'Ready-to-use app',
      'Source code & build files',
      'Further development & maintenance'
    ]
  }
]

export const EndToEndStages = () => {
  const [open, setOpen] = useState(false)

  const handleOpen = i => {
    if (window.innerWidth > 1030) return
    if (i === open) setOpen(false)
    else setOpen(i)
  }

  return (
    <section className="staff-augmentation__section sa_remote-teams ete">
      <div className="staff-augmentation__title">
        <p className="staff-augmentation__title-text">Covering</p>
        <p className="staff-augmentation__title-text">all stages of SDLC</p>
      </div>

      <div className="staff-augmentation__remote-blocks-wrap">
        {stages.map(({ heading, text }, i) => (
          <div
            key={'item_' + i}
            className={'staff-augmentation__remote-block' + (open === i ? ' open' : '')}
            onClick={() => handleOpen(i)}
          >
            <div className="staff-augmentation__remote-header">
              <p className="staff-augmentation__remote-heading">{heading}</p>
              <p className="staff-augmentation__remote-heading">0{i + 1}</p>
              <ChevronRight className="staff-augmentation__remote-heading" />
            </div>
            <div className="staff-augmentation__remote-text">
              {text.map((itm, i) => (
                <div className="ete_stages-point" key={'stg_' + i}>
                  <div>
                    <BulletArrow className="ete_stages-point-bullet" />
                  </div>
                  {itm}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
