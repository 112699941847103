import React, {useState} from 'react'
import {Button} from '@mui/material'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ReactComponent as Arrow} from 'images/arrow.svg'
import {ReactComponent as Mail} from 'images/mail.svg'
import {ReactComponent as Tg} from 'images/telegram.svg'
import Eugene from 'images/eugene_korpan.jpg'
import UploadButton from 'components/reusable/UploadButton'
import {Close} from '@mui/icons-material'
import InputField from 'components/reusable/InputField/InputField'
import Select from 'components/reusable/Select/Select'
import Spinner from "../reusable/Spiner/Spiner";

export const ContactUsForm = ({ isLoading, data, careers, handleChange, handleFileRemoval, handleSubmit, devs,}) => {
  const [isSelectorOpen, setisSelectorOpen] = useState(false)

  return (
    <div className="form__container contact__form">
      <div className="form__left-section">
        <h3 className="form__title contact__text">
          {devs
            ? 'Fill out the form so that we can send you an email with relevant CVs'
            : "Let's talk about your project"}
        </h3>
        <div className="form__double-field contact__text">
          <InputField
            label="Name"
            onChange={handleChange('name')}
            placeholder="Name"
            value={data.name}
            color="black"
            helperColor="red"
            helperText={data.error.name && 'Name field must contain at least 2 characters'}
          />
          <InputField
            label="Email"
            onChange={handleChange('email')}
            placeholder="Email"
            value={data.email}
            color="black"
            helperColor="red"
            helperText={data.error.email && 'Invalid email'}
          />
        </div>
        <div className="contact__input_wrapper">
          <InputField
            label="Message"
            onChange={handleChange('message')}
            multiline={true}
            placeholder="Message"
            value={data.message}
            color="black"
            helperColor="red"
            helperText={data.error.message && 'The message must contain at least 10 characters'}
          />

          {/*{!devs && (*/}
          {/*  <Select*/}
          {/*    data={data}*/}
          {/*    handleChange={handleChange('vacancy')}*/}
          {/*    isSelectorOpen={isSelectorOpen}*/}
          {/*    setisSelectorOpen={setisSelectorOpen}*/}
          {/*    menuItems={careers}*/}
          {/*  />*/}
          {/*)}*/}
        </div>

        <div className="form__files">
          {[...data.files].map(file => (
            <div className="form__files-file-wrap contact__file-wrap" key={file.name}>
              <div className="form__files-file">{file.name}</div>
              <Close onClick={() => handleFileRemoval(file.name)}/>
            </div>
          ))}
        </div>

        <div className="form__double-field contact__upload_btn">
          {/*<UploadButton*/}
          {/*  text={devs ? 'ATTACH A FILE' : 'UPLOAD CV'}*/}
          {/*  handleChange={handleChange}*/}
          {/*  buttonIconColor="black"*/}
          {/*/>*/}

          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            <p>SEND A MESSAGE</p>
            <Arrow fill="white"/>
            {isLoading && <Spinner/>}
          </Button>

        </div>
      </div>

      {!devs && (
        <div className="form__right-section contact__right-section">
          <div className="form__right-section-top contact__top-section">
            <h3 className="form__title">Contact us</h3>
            <p className="form__text">
              Drop us a line about your needs or send the project requirements (tech stack, the scope of work,
              timeframes, business idea) so that we could provide you with the cooperation proposal
            </p>
          </div>
          <div className="form__right-section-bottom contact__right-section-bottom">
            <div className="contact__image-wrapper">
              <img
                className="form__hr-photo contact__photo_border"
                alt="Eugene Korpan"
                src={Eugene}
                draggable={false}
              />
            </div>
            <div className="form__hr-info">
              <h3 className="form__hr-name">EUGENE KORPAN</h3>
              <p className="form__hr-position">CEO</p>
              <div className="form__double-field">
                <a className="form__hr-contact contact__btn_border" href="mailto:eugene@milestep.io">
                  <Mail fill="black"/>
                  Email
                </a>
                <a className="form__hr-contact contact__btn_border" href="https://t.me/eugenekorpan">
                  <Tg fill="black"/>
                  Telegram
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
