/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as Arrow } from 'images/Cases/arrow2.svg'
import { ReactComponent as BulletArrow } from 'images/careerBullet.svg'
import { ReactComponent as ChevronRight } from 'images/chevron-right.svg'

export const TextBlocksWithPoints = ({ icons, items, title }) => {
  const [open, setOpen] = useState(false)
  const [isMobileWidth, setMobileWidth] = useState(false)

  useEffect(() => {
    const handleMobileWidth = () => {
      if (window.innerWidth <= 576) setMobileWidth(true)
      else if (isMobileWidth) setMobileWidth(false)
    }

    handleMobileWidth()

    window.addEventListener('resize', handleMobileWidth)

    return () => {
      window.removeEventListener('resize', handleMobileWidth)
    }
  }, [])

  const handleOpen = i => {
    if (!isMobileWidth) return

    if (i === open) setOpen(false)
    else setOpen(i)
  }

  return (
    <section className="staff-augmentation__section">
      <div className="staff-augmentation__title sa_technologies">
        <p className="staff-augmentation__title-text">{title}</p>
      </div>

      <div className="staff-augmentation__technologies-wrap">
        {items.map((item, i) => (
          <div
            onClick={() => handleOpen(i)}
            key={item.title + i}
            className={'staff-augmentation__technologies-item' + (open === i ? ' open' : '')}
          >
            <div className="staff-augmentation__technologies-item-title">
              <div className="staff-augmentation__technologies-item-arrow-wrap">
                <Arrow className="staff-augmentation__technologies-item-arrow sa_icon-arrow" />
              </div>
              <div className="staff-augmentation__technologies-item-content">
                <div>{icons[i]}</div>
                {item.title}
              </div>
            </div>

            <div className="staff-augmentation__technologies-item-points">
              <div className="staff-augmentation__technologies-item-points-div">
                {item.title}

                <ChevronRight className="staff-augmentation__technologies-item-arrow sa_icon-chevron" />
                <Arrow className="staff-augmentation__technologies-item-arrow sa_icon-arrow" />
              </div>
              <div className="staff-augmentation__technologies-item-points-div">
                {item.points.map(pt => (
                  <div className="staff-augmentation__technologies-item-point" key={item.title + pt + i}>
                    <div className="staff-augmentation__technologies-item-point-bullet-wrap">
                      <BulletArrow className="staff-augmentation__technologies-item-point-bullet" />
                    </div>
                    {pt}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

TextBlocksWithPoints.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.element).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      points: PropTypes.arrayOf(PropTypes.string)
    })
  ).isRequired,
  title: PropTypes.string.isRequired
}
