import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WebFont from 'webfontloader'

import Home from 'components/Home/Home'
import { getHome } from 'features/homeSlice'
import Loader from 'components/reusable/Loader/LoaderHome'

function HomeContainer ( props )
{
  const dispatch = useDispatch()
  const home_page_cards = useSelector( state => state.home.home.home_page_cards )
  const feedbacks = useSelector( state => state.home.home.feedbacks )
  const [ isReady, setIsReady ] = useState(false)

  useEffect( () =>
  {
    dispatch( getHome( ))
    WebFont.load( {
      custom: {
        families: [ 'Zona Pro' ],
        urls: [ '../styles/fonts.css' ]
      },
      active: function() {
        setIsReady(true)
      }
    } )
  }, [] )

  if ( home_page_cards && feedbacks && isReady ) return <Home home_page_cards={ home_page_cards } feedbacks={ feedbacks }/>
  else return <Loader />
}

export default HomeContainer
