import { ReactComponent as Arrow } from 'images/careerBullet.svg'

const CareerList = ({ items, title }) => (
  <div className="careers__offer-section">
    <h4 className="careers__offer-section-title">{title}</h4>
    <div className="careers__offer-list">
      {items.map(item => (
        <div className="careers__offer-list-item" key={title + Math.random()}>
          <div>
            <Arrow />
          </div>
          <div>{item}</div>
        </div>
      ))}
    </div>
  </div>
)

export default CareerList
