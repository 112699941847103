import React from 'react'

export const StaffAugmentDevsAvailableOptions = ({
  serviceStep: { title, options, multiple },
  selected,
  handleClick
}) => {
  return (
    <div>
      <div className="staff-augmentation__title">
        <p className="staff-augmentation__title-text">{title}</p>
      </div>
      <div className="staff-augmentation__devs-services-wrap">
        {options.map(option => (
          <div
            key={'sa_dev_opt-' + option}
            className={`staff-augmentation__devs-service${selected?.includes(option) ? ' selected' : ''}`}
            onClick={() => handleClick(option, multiple)}
          >
            {option}
          </div>
        ))}
      </div>
    </div>
  )
}
