import React from 'react'
import '../../styles/components/magento/magento-company.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {ReactComponent as BlockSettle} from 'images/MagentoDevelopment/BlockSettle.svg'
import {ReactComponent as Boon} from 'images/MagentoDevelopment/boon.svg'
import {ReactComponent as Naprok} from 'images/MagentoDevelopment/naprok.svg'
import {ReactComponent as OneKIN} from 'images/MagentoDevelopment/OneKIN.svg'
import {ReactComponent as Brokerkit} from 'images/MagentoDevelopment/Brokerkit.svg'
import {ReactComponent as SHIFT} from 'images/MagentoDevelopment/SHIFT.svg'
import {ReactComponent as Fredom} from 'images/MagentoDevelopment/FREDOM.svg'
import {ReactComponent as LandLodrsNY} from 'images/MagentoDevelopment/landlordsNY.svg'
import {ReactComponent as Upspeak} from 'images/MagentoDevelopment/Upspeak.svg'
import {ReactComponent as Nobi} from 'images/MagentoDevelopment/nobi.svg'
import {ReactComponent as Go2Meet} from 'images/MagentoDevelopment/go2Meet.svg'
import {ReactComponent as TrendHero} from 'images/MagentoDevelopment/trendHERO.svg'
import {ReactComponent as Maxeda} from 'images/MagentoDevelopment/MAXEDA.svg'
import {ReactComponent as Upstack} from 'images/MagentoDevelopment/UPSTACK.svg'
import {ReactComponent as PushPress} from 'images/MagentoDevelopment/pushPress.svg'
import {ReactComponent as Onpodio} from 'images/MagentoDevelopment/onpodio.svg'
import {ReactComponent as Zippifi} from 'images/MagentoDevelopment/zippifi.svg'
import {ReactComponent as Mediprocity} from 'images/MagentoDevelopment/mediprocity.svg'
import {ReactComponent as Swipecast} from 'images/MagentoDevelopment/swipecast.svg'
import {ReactComponent as Myfitpod} from 'images/MagentoDevelopment/MyFitPod.svg'


const companies = [
  BlockSettle, Boon, Naprok, OneKIN, Brokerkit, SHIFT, Fredom, LandLodrsNY, Upspeak, Nobi, Go2Meet, TrendHero, Maxeda, Upstack, PushPress,
  Onpodio, Zippifi, Mediprocity, Swipecast, Myfitpod
];

const settings = {
  speed: 5000,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: 'linear',
  slidesToShow: 5,
  slidesToScroll: 1,
  infinite: true,
  swipeToSlide: true,
  centerMode: true,
  focusOnSelect: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
}


export const MagentoDeveloperCompany = () => {
  return (
    <section className="magento-developer__section">
      <h2 className="magento-developer__title">Companies that trust us</h2>

      {window.screen.width >= 769 &&
        <div className="magento-developer__company-wrap">
          {companies.map((Component, i) =>
            <div className="magento-developer__company" key={i}><Component/></div>
          )}
        </div>
      }

      {window.screen.width <= 768 &&
          <Slider {...settings}>
            {companies.map((Component, i) =>
              <div className="magento-developer__company" key={i}><Component/></div>
            )}
          </Slider>
      }
    </section>
  )
}


