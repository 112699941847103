import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import api from 'helpers/api'
import { useContactForm } from 'hooks/contactForm'
import { ContactUsForm } from 'components/ContactUs/form'
import {toast} from "react-toastify";

const ContactUsContainer = () => {
  const { id } = useParams()

  const [careers, setCareers] = useState([])
  const [offer, setOffer] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const onSubmit = async (data) => {

    try {
      setIsLoading(true);
      await api.Careers.post(data)
      setIsLoading(false);
      toast.success('Your message was successfully sent');
    } catch (err) {
      setError(err);
      setIsLoading(false);
      toast.error("An error occurred while sending the data");
    }
  }

  const { formData, handleChange, handleFileRemoval, handleSubmit } =
    useContactForm(onSubmit)

  useEffect(() => {
    api.Careers.get()
      .then(r => setCareers(r.data))
      .catch(e => console.error(e.message))
  }, [])

  useEffect(() => {
    if (id && careers.length) {
      setOffer(careers.filter(c => c.slug === id)[0])
    }

    return () => setOffer(null)
  }, [id, careers])

  return (
    <div className='contact__wrapper'>
      <ContactUsForm
        error={error}
        isLoading={isLoading}
        handleChange={handleChange}
        handleFileRemoval={handleFileRemoval}
        handleSubmit={handleSubmit}
        data={formData}
        careers={careers}
      />
    </div>
  )
}

export default ContactUsContainer
