import React from 'react'
import {isMacOs} from 'react-device-detect'
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded'

import Button from '../reusable/Button/Button'
import Background from '../../images/Home/Background.svg'
import Background2 from '../../images/Home/Background2.jpg'
import G2Small from '../../images/Home/G2Small.svg'
import GoodFirmsSmall from '../../images/Home/GoodFirmsSmall.svg'
import ClutchSmall from '../../images/Home/Numbers/Clutch.svg'
import ClutchRed from '../../images/Home/Clutch-red.svg'
import FireAnimationDesktop from '../reusable/FireAnimation/fire_animation_desktop'
import FireAnimationMobile from '../reusable/FireAnimation/fire_animation_mobile'

const scrollToBottom = () => {
  window.scrollTo({
    top: window.innerHeight - 90,
    behavior: 'smooth'
  })
}

export const EndToEndTopSection = () => (
  <section className="about__wrapper  ete  sa_top">
    <div className="about__column-first">
      <div className="about__row-main">
        <div className="about__column-main-first">
          <div className="about__row-main-text">
            <div className="about__text-main">END-TO-END SOFTWARE</div>
          </div>
          <div className="about__row-main-text">
            <div className="about__text-main">DEVELOPMENT</div>
          </div>
          <div className="about__row-main-text-2">
            <div className="about__text-main-secondary" style={isMacOs ? {marginTop: '1%'} : undefined}>
              Custom software solutions that meet business objectives and bring about long-lasting results.&nbsp;
              <span className="about__text-main-secondary-desktop-only">
                Working with products at any stage: from MVP to complex market-ready solutions
              </span>
            </div>
          </div>
        </div>
        <FireAnimationDesktop/>
      </div>

      <div className="about__column-main-second-mobile">
        <div className="about__row-main-second-mobile">
          <div className="about__g2-scrol">
            <img src={ClutchSmall} alt="about" className="about__g2-small"/>
            <img src={GoodFirmsSmall} alt="about" className="about__g2-small"/>
            <img src={G2Small} alt="about" className="about__g2-small"/>
          </div>
        </div>
      </div>

      <div className="about__row-image-first-mobile">
        <FireAnimationMobile/>
      </div>

      <div className="about__row-image-first-mobile">
        <div className="about__div-image-9-mobile">
          {' '}
          <img src={Background2} alt="about" className="about__div-image-main-mobile-2"/>{' '}
        </div>
      </div>

      <div className="about__div-image-main-mobile"></div>
      <img src={Background} alt="about" className="about__div-image-main"/>
      <div className="about__div-image-main-2"></div>

      <div className="about__row-info">
        <div className="about__to-bottom">
          <Button
            className="about__button-to-bottom"
            onClick={scrollToBottom}
            text={<ArrowDownwardRoundedIcon/>}
            classNameText="about__button-to-bottom-text"
          />
        </div>
        <div className="about__to-bottom-second">
          <img src={ClutchRed} alt="about" className="about__g2-main"/>
        </div>
        <div className="about__to-bottom-second">
          <img src={GoodFirmsSmall} alt="about" className="about__g2-main"/>
        </div>
        <div className="about__to-bottom-second">
          <img src={G2Small} alt="about" className="about__g2-main"/>
        </div>
      </div>
    </div>
  </section>
)
