import React from 'react'
import { NavLink } from 'react-router-dom'

import style from '../../../styles/components/Menu.module.css'

const Menu = ( props ) =>
{
  return (
    <div className={ `${ style.burgerMenu } ${ props.isOpen ? style.active : null }` }>
      <div className='menu__navItems'>
        <div className='menu__navItemWrap' onClick={ () => props.toggle() }>
          <NavLink
            to="/staff-augmentation"
            className={({ isActive }) => 'menu__navItem' + (isActive ? ' menu__activeLink' : '')}
          >
            <div className='menu__navLinkWrap'>
              <span className='menu__navLink'>Staff augmentation</span>
            </div>
          </NavLink>
        </div>

        <div className='menu__navItemWrap' onClick={ () => props.toggle() }>
          <NavLink
            to="/end-to-end"
            className={ ( { isActive } ) => 'menu__navItem' + ( isActive ? ' menu__activeLink' : '' ) }
          >
            <div className='menu__navLinkWrap'>
              <span className='menu__navLink'>End-to-end software development</span>
            </div>
          </NavLink>
        </div>

        <div className='menu__navItemWrap' onClick={ () => props.toggle() }>
          <NavLink
            to="/magento"
            className={ ( { isActive } ) => 'menu__navItem' + ( isActive ? ' menu__activeLink' : '' ) }
          >
            <div className='menu__navLinkWrap'>
              <span className='menu__navLink'>Magento development services</span>
            </div>
          </NavLink>
        </div>


        <div className='menu__navItemWrap' onClick={ () => props.toggle() }>
          <NavLink
            to="/portfolio"
            className={({ isActive }) => 'menu__navItem' + (isActive ? ' menu__activeLink' : '')}
          >
            <div className='menu__navLinkWrap'>
              <span className='menu__navLink'>Portfolio</span>
            </div>
          </NavLink>
        </div>
        <div className='menu__navItemWrap' onClick={ () => props.toggle() }>
          <NavLink
            to="/about"
            className={({ isActive }) => 'menu__navItem' + (isActive ? ' menu__activeLink' : '')}
          >
            <div className='menu__navLinkWrap'>
              <span className='menu__navLink' >Pricing</span>
            </div>
          </NavLink>
        </div>
        <div className='menu__navItemWrap' onClick={ () => props.toggle() }>
          <NavLink
            to="/careers"
            className={({ isActive }) => 'menu__navItem' + (isActive ? ' menu__activeLink' : '')}
          >
            <div className='menu__navLinkWrap'>
              <span className='menu__navLink' >Careers</span>
            </div>
          </NavLink>
        </div>
        <div className='menu__navItemWrap' onClick={ () => props.toggle() }>
          <NavLink
            to="/blog"
            className={ ( { isActive } ) => 'menu__navItem' + ( isActive ? ' menu__activeLink' : '' ) }
          >
            <div className='menu__navLinkWrap'>
              <span className='menu__navLink' >Blog</span>
            </div>
          </NavLink>
        </div>
        <div className='menu__navItemWrap' onClick={ () => props.toggle() }>
          <NavLink
            to="/contact-us"
            className={({ isActive }) => 'menu__navItem' + (isActive ? ' menu__activeLink' : '')}
          >
            <div className='menu__navLinkWrap'>
              <span className='menu__navLink' >Contact us</span>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default Menu
