import React, { useState } from 'react'

import { ReactComponent as Arrow } from 'images/Cases/arrow2.svg'
import { ReactComponent as ChevronRight } from 'images/chevron-right.svg'

const content = [
  {
    title: 'AGILE PROCESSES',
    text: 'Daily stand-ups, sprints planning & reviews, access to ongoing tasks status, demo days'
  },
  {
    title: 'MANAGEMENT EFFICIENCY',
    text: 'Project & scope of work estimation, tasks planning, prioritization, documentation, reportings'
  },
  {
    title: 'CONTROL CODE QUALITY',
    text: 'Regular code reviews, style guides, detailed requirements, TDD, coding best practices'
  },
  {
    title: 'DELIVERY PROCESSES',
    text: 'Multiple build environments, CI/CD pipeline, incremental delivery for complex products, coverage by unit, integration, end-to-end tests'
  },
  {
    title: 'PRODUCT MAINTAINABILITY',
    text: 'Following industry standards of system design, architecture tailored to business needs, scalable and secure infrastructure'
  },
  {
    title: 'DATA COMPLIANCE STANDARDS',
    text: 'Following GDPR, PCI-DSS, HI PAA, CCPA, and other data privacy regulations'
  }
]

export const EndToEndWWYG = () => {
  const [pos, setPos] = useState(0)

  const blockWidth = window.innerWidth * 0.9 + window.innerWidth * 0.05

  const prevBlock = () => {
    if (pos > 0) setPos(ps => ps - 1)
  }
  const nextBlock = () => {
    if (pos < 5) setPos(ps => ps + 1)
  }

  return (
    <section className="staff-augmentation__section end-to-end_wwyg">
      <div className="staff-augmentation__title sa_technologies">
        <p className="staff-augmentation__title-text">What will you get</p>
      </div>

      <div className="end-to-end__services-wrap">
        <div className="end-to-end__services" style={{ transform: `translateX(-${pos * blockWidth}px)` }}>
          {content.map(({ title, text }, i) => (
            <div className="end-to-end__service" key={title}>
              <p className="end-to-end__service-number">0{i + 1}</p>
              <p className="end-to-end__service-title">{title}</p>
              <p className="end-to-end__service-text">{text}</p>
              <Arrow className="link-arrow-black" />
            </div>
          ))}
        </div>
      </div>

      <div className="staff-augmentation__text-blocks-buttons">
        <button className="staff-augmentation__text-blocks-button" onClick={prevBlock} disabled={pos === 0}>
          <ChevronRight />
        </button>
        <button className="staff-augmentation__text-blocks-button" onClick={nextBlock} disabled={pos === 5}>
          <ChevronRight />
        </button>
      </div>
    </section>
  )
}
