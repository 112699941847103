import React from 'react';
import '../../styles/components/magento/magento-what-we-do.scss'
import {ReactComponent as Star} from 'images/MagentoDevelopment/star.svg'
import Work from 'images/MagentoDevelopment/work.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useRef, useState} from 'react'
import Slider from "react-slick";
import {NextArrow1, PrevArrow1,} from "../reusable/Slick/slider-arrow";


export const MagentoWhatWeDo = () => {

  const sliderRef = useRef()
  const [arrowIndex, setArrowIndex] = useState(0)

  const beforeChange = (_, nextIndex) => setArrowIndex(nextIndex)


  const settings = {
    ref: sliderRef,
    dots: false,
    arrows: false,
    beforeChange,
    infinite: false,
    speed: 500,
    slidesToShow: window.screen.width > 363 ? 2 : 1,
    slidesToScroll: 1,
  };

  const onNext = () => {
    if (!sliderRef.current) return false
    sliderRef.current.slickNext()
  }
  const onPrev = () => {
    if (!sliderRef.current) return false
    sliderRef.current.slickPrev()
  }

  return (
    <div className="magento-what-we-do__section">
      <div className="magento-what-we-do__title"> What we do

        <div className="magento-what-we-do__arrows">
          <div className="magento-what-we-do__prev">
            <PrevArrow1 active={arrowIndex === 0} onClick={onPrev}/>
          </div>
          <div className="magento-what-we-do__next">
            <NextArrow1 active={arrowIndex === 1} onClick={onNext}/>
          </div>
        </div>


        <Slider {...settings}>
          <div className="magento__block">
            <li className='magento-text'><Star/>Custom Website Development</li>
            <li className='magento-text'><Star/>Platform Migration to Magento</li>
            <li className='magento-text'><Star/>Magento SEO Optimization</li>
            <li className='magento-text'><Star/>Magento Data Migration</li>
            <li className='magento-text'><Star/>Magento App Development</li>
            <li className='magento-text'><Star/>Magento Version Upgrades</li>
            <li className='magento-text'><Star/>Performance Audits & Optimization</li>
            <li className='magento-text'><Star/>Magento Security Solutions</li>
          </div>

          <div className="magento__block2">
            <li className='magento-text'><Star/>Theme Design and Integration</li>
            <li className='magento-text'><Star/>Magento API Development</li>
            <li className='magento-text'><Star/>Payment & Shipping Integration</li>
            <li className='magento-text'><Star/>Magento Cloud Development</li>
            <li className='magento-text'><Star/>Magento Module Development</li>
            <li className='magento-text'><Star/>Multi-store Setup and Management</li>
            <li className='magento-text'><Star/>Magento API Integration</li>
            <li className='magento-text'><Star/>Consulting Services</li>
          </div>
        </Slider>
      </div>

      <div className="magento-image">
        <img src={Work} alt=""/>
      </div>

    </div>
  );
}

