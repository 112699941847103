import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from "react-toastify";

import './App.scss'
import About from './components/About/About'
import Blog from './components/Blog/Blog'
import CasesContainer from './containers/CasesContainer'
import Competences from './components/Competences/Competences'
import NavBar from './components/NavBar/NavBar'
import Footer from './components/Footer/Footer'
import ProjectContainer from './containers/ProjectContainer'
import CareersContainer from './containers/CareersContainer'
import HomeContainer from 'containers/HomeContainer'
import ScrollToTop from 'helpers/ScrollToTop'
import Error from 'components/reusable/Error/Error'
import ContactUsContainer from 'containers/ContactUsContainer'
import PricingContainer from 'containers/PricingContainer'
import { StaffAugmentation } from 'components/StaffAugmentation'
import { EndToEndSoftwareDevelopment } from 'components/EndToEnd'
import  {MagentoDevelopmentServices} from "./components/Magento/magento-services";

export default function App() {
  return (
    <>
      <NavBar />

      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeContainer />} />
        {/* <Route path="/about" element={ <About /> } />
        <Route path="/blog" element={ <Blog /> } /> */}
        <Route path="/about" element={<Error soon />} />
        <Route path="/blog" element={<Error soon />} />
        <Route path="/end-to-end" element={<EndToEndSoftwareDevelopment />} />
        <Route path="/magento" element={<MagentoDevelopmentServices/>} />

        <Route path="/careers" element={<CareersContainer />} />
        <Route path="/careers/:id" element={<CareersContainer />} />
        <Route path="/portfolio" element={<CasesContainer />} />
        <Route path="/portfolio/:id" element={<ProjectContainer />} />
        <Route path="/competences" element={<Competences />} />
        <Route path="/contact-us" element={<ContactUsContainer />} />
        <Route path="/staff-augmentation" element={<StaffAugmentation />} />
        <Route exect path="/pricing" element={<PricingContainer />} />
        <Route path="*" element={<Error />} />
      </Routes>

      <div className="about__footer">
        <div className="about__footer-content">
          <Footer />
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  )
}
